:root{   
    --sttl-font-size-12: 0.75rem;
    --sttl-font-size-14: 0.875rem;
    --sttl-font-size-16: 1rem;
    --sttl-font-size-18: 1.125rem;
    --sttl-font-size-20: 1.25rem;
    --sttl-font-size-22: 1.375rem;
    --sttl-font-size-24: 1.5rem;
    --sttl-font-size-26: 1.625rem; 
    --sttl-font-size-28: 1.75rem; 
    --sttl-font-size-30: 1.875rem; 
    --sttl-font-size-32: 2rem;
    --sttl-font-size-34: 2.125rem;
    --sttl-font-size-36: 2.25rem;
    --sttl-font-size-38: 2.375rem;
    --sttl-font-size-40: 2.5rem;
    --sttl-font-size-42: 2.625rem;
    --sttl-font-size-44: 2.75rem;
    --sttl-font-size-46: 2.875rem;
    --sttl-font-size-48: 3rem;
    --sttl-font-size-50: 3.125rem;
    --sttl-font-size-52: 3.25rem;
    --sttl-font-size-54: 3.375rem;
    --sttl-font-size-56: 3.5rem;
    --sttl-font-size-58: 3.625rem;
    --sttl-font-size-60: 3.75rem;
}
@import '../../../css/mixin';

.schemeSection{
    // background: linear-gradient(291deg, #1b2f68, #0e1834, #0f2669);
    // background-size: 600% 600%;

    // -webkit-animation: schemeGradientAnimation 9s ease infinite;
    // -moz-animation: schemeGradientAnimation 9s ease infinite;
    // -o-animation: schemeGradientAnimation 9s ease infinite;
    // animation: schemeGradientAnimation 9s ease infinite;    
    padding: 70px 0 0px;
    // background-color: #F6F4F4;
    h2{
        @include customFont(26px);
        color:var(--sttl-font-white);
        line-height: 24px;        
        font-weight: 600;
    }
    .schemeNav {
        width: 60px;
        display: flex;
        justify-content: space-between;

        .prev,
        .next {
            background-image: url(../../../images/round-arrow.svg);
            width: 25px;
            height: 25px;
            cursor: pointer;
            background-repeat: no-repeat;
        }

        .prev.swiper-button-disabled,
        .next.swiper-button-disabled {
            background-image: url(../../../images/round-arrow-gray.svg);
            pointer-events: none;
        }

        .prev {
            transform: rotate(180deg);
        }
    }
    .swiper{
        margin-top: 20px;
        padding-bottom: 20px;
        .swiper-pagination{
            bottom:0;
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
            }
            .swiper-pagination-bullet-active{
                width: 20px;
                height: 20px;
                background-color: var(--sttl-font-primary);
            }
        }
    }
    .schemeInner {
        border-radius: 10px;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        display: flex;
        box-shadow:  0 3px 50px #00000025;
        img{
            // border-radius: 10px;
            width: 100%;
            height: 160px;
            object-fit: cover;
            flex: 0 0 auto;
            max-width: 50%;
            transition: 0.3s all ease-in-out;
        }
        h5 {
            font-size: var(--sttl-font-size-20);
            font-weight: 500;
            // position: absolute;
            // bottom: 0;
            background-color: var(--sttl-font-white);
            width: 100%;
            padding: 30px;
            margin: 0;
            // border-radius: 0 0 10px 10px;
            color: #323232;
            z-index: 9;
            @include transition();
            flex: 0 0 auto;
            max-width: 50%;
            display: flex;
            align-items: center;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
            h5 {
                background-color: var(--sttl-font-primary);
                bottom: 0;
                color: var(--sttl-font-white);
            }
        }       
    }
}

@-webkit-keyframes schemeGradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes schemeGradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes schemeGradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes schemeGradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}