:root{   
    --sttl-font-size-12: 0.75rem;
    --sttl-font-size-14: 0.875rem;
    --sttl-font-size-16: 1rem;
    --sttl-font-size-18: 1.125rem;
    --sttl-font-size-20: 1.25rem;
    --sttl-font-size-22: 1.375rem;
    --sttl-font-size-24: 1.5rem;
    --sttl-font-size-26: 1.625rem; 
    --sttl-font-size-28: 1.75rem; 
    --sttl-font-size-30: 1.875rem; 
    --sttl-font-size-32: 2rem;
    --sttl-font-size-34: 2.125rem;
    --sttl-font-size-36: 2.25rem;
    --sttl-font-size-38: 2.375rem;
    --sttl-font-size-40: 2.5rem;
    --sttl-font-size-42: 2.625rem;
    --sttl-font-size-44: 2.75rem;
    --sttl-font-size-46: 2.875rem;
    --sttl-font-size-48: 3rem;
    --sttl-font-size-50: 3.125rem;
    --sttl-font-size-52: 3.25rem;
    --sttl-font-size-54: 3.375rem;
    --sttl-font-size-56: 3.5rem;
    --sttl-font-size-58: 3.625rem;
    --sttl-font-size-60: 3.75rem;
}
@import '../../../css/mixin';
.reportSection{
    [aria-hidden=true]{
        display:block;
    }
    [role=dialog]{
        background-color: rgba(0, 0, 0,0.8)!important;
    }
    .reportHeading{
        h2{
            @include customFont(26px);
        }
    }
}