.financialForm.misNew.praapti{
    .financialMainFrom {
        h4{
            color: var(--sttl-font-secondary);
            font-weight: 700;
            margin-bottom: 5px;
        }
        h6{
            color: var(--sttl-font-primary);
            font-size: var(--sttl-font-size-18);
        }
        .radioTitleSection{
            .radioYear { 
                padding: 10px 0;
                margin-bottom: 0;
                .form-check{
                    border:0;
                    padding: 0; 
                    span{
                        font-size: var(--sttl-font-size-18);
                        font-weight: 700;
                        margin-left: 10px;
                    }
                }
                input[type="date"]{
                    width: 100%;
                }
                .bsSearchBtn.mt-0{
                    height: 40px;
                    line-height: 40px;
                    padding: 0 30px;
                    &.searchbtnIcn{
                        width: 40px;
                        padding: 0;
                        line-height: 29px;
                        font-size: 20px;
                        border-color: var(--sttl-font-primary);
                        &:hover svg{
                            color: var(--sttl-font-primary);
                        }
                    }
                }
                input[type="date"]{
                    padding: 0 7px;
                }
                .misReportSelect{
                    height: 40px;
                    input{
                        height: 40px;
                    }
                    .css-qbdosj-Input {
                        height: 37px;
                        padding: 0;
                    }
                    
                    .css-1fdsijx-ValueContainer, .css-b62m3t-container, .css-13cymwt-control{
                        height: 40px;
                        padding: 0 8px;
                    }
                    .css-13cymwt-control{
                        padding: 0;
                    }
                    .css-1n3hvhd-control{
                        box-shadow: none;
                        border-color:hsl(0, 0%, 70%);
                        &:hover{
                            border-color:hsl(0, 0%, 70%)
                        }
                    }
                }
            }
        }
        table.table{
            thead{
                th{
                    vertical-align: middle;
                    font-size: var(--sttl-font-size-14);
                    text-align: center;
                    min-width: 100px;
                }
            }
            tbody{
                td{
                    vertical-align: middle;
                    font-size: var(--sttl-font-size-14);
                    text-align: center;
                }
            }
        }
        .bsBtn{
            margin-top: 15px;
            background-color: var(--sttl-font-primary);
            color: var(--sttl-font-white);
            border-radius: 7px;
            padding: 10px 30px;
            border: 1px solid;
            text-decoration: none;
            &:hover{
                background-color: transparent!important;
                color: var(--sttl-font-primary)!important;
            }
        }
    }
}
.praapti-report div.text-left { text-align: left; }
.praapti-report div.text-right { text-align: right; }