@import '../../css/mixin';
.logoSection{
    padding: 18px 0;
    position: relative;
    z-index: 9;
    background-color: #fff;
    .logoSwiper{
        .swiper-wrapper{
            align-items: center;
            text-align: center;
        }
    }
}
.smokeModal .modal-dialog {
    max-width: 500px!important;
}
footer{
    position: relative;
    z-index: 9;
    background-color: var(--sttl-font-secondary);
    padding: 21px 0;
    color: var(--sttl-font-white);
    @include customFont(13px);
    ul {
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            &:first-child a {
                padding-left: 0;
            }
            &:last-child a {
                padding-right: 0;
                &::before{
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: var(--sttl-font-white);
                @include customFont(13px);
                padding: 0 6px;
                position: relative;
                &:hover{
                    color: var(--sttl-font-primary);
                }
                &::before {
                    content: '';
                    background: var(--sttl-font-white);
                    width: 1px;
                    height: 10px;
                    position: var(--sttl-position-absolute);
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .social{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{     
            transition: all 0.3s ease-out;
            &:hover{
                transform: rotateZ(-20deg);
            }
            a{
                font-size: var(--sttl-font-size-30);
                &::before{
                    display: none;
                }
            }
        }
    }
}
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--sttl-font-primary);
    color: #fff;
    border: 1px solid var(--sttl-font-primary);
    border-radius: 100%;
    font-size: 24px;
    z-index: 1024;
    font-weight: 600;
    width: 50px;
    height: 50px;
    transition: 0.3s all ease-in-out;
}
  
  .back-to-top:hover {
    background-color: transparent;
    color: var(--sttl-font-primary);
  }