@import "../css/_mixin.scss";
.fs-regular {
  font-size: var(--sttl-font-size-16) !important;
}
.btn-success {
  background: #54a800;
  color: var(--sttl-font-white);
  text-decoration: none;
  padding: 11px 27px;
  border-radius: 100px;
  border: 0;
}
.financialForm {
  .fw-medium {
    font-weight: 500;
  }
  .radioYear {
    background: #a6bee136;
    padding: 10px 0;
    border: 1px solid #ced3df;
    margin-top: 20px;
    height: 45px;
    input[type="text"],
    input[type="date"],
    select {
      font-size: var(--sttl-font-size-14);
      color: #323232;
      height: 40px;
      padding: 0 10px;
      border-radius: 5px;
      border: 1px solid #cccccc;
    }
    input[type="radio"] {
      margin-top: 7px;
    }
    label {
      font-size: var(--sttl-font-size-20);
      font-weight: var(--sttl-font-medium);
      &.h6 {
        font-size: var(--sttl-font-size-16);
      }
    }
  }
  .gfr12A {
    label {
      font-size: var(--sttl-font-size-12) !important;
      margin-left: 3px;
    }
  }

  .year {
    // background: #A6BEE136;
    // padding: 14px 0;
    // border: 1px solid #CED3DF;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
      width: 178px;
      height: 40px;
      border-radius: 6px;
    }

    label {
      color: var(--sttl-font-secondary);
      padding-right: 15px;
    }

    .inputBtn {
      margin-left: 15px;
      background: var(--sttl-font-secondary);
      width: 35px;
      height: 35px;
      border-radius: 100%;
      line-height: 32px;
      text-align: center;
      position: relative;

      input[type="submit"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        opacity: 0;
      }
    }
    h5 {
      color: var(--sttl-font-secondary);
    }
    .switch-field {
      display: flex;
      overflow: hidden;
      input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
      }
      label {
        background-color: transparent;
        color: #1b2f68;
        font-size: 13px;
        text-align: center;
        padding: 2px 16px;
        border: 1px solid #1b2f68;
        transition: all 0.1s ease-in-out;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .switch-field input:checked + label {
      background-color: #1b2f68;
      border: 1px solid #1b2f68;
      color: #fff;
    }

    .switch-field label:first-of-type {
      border-radius: 50px 0 0 50px;
    }

    .switch-field label:last-of-type {
      border-radius: 0 50px 50px 0;
    }

    .form {
      max-width: 600px;
      font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
      font-weight: normal;
      line-height: 1.625;
      margin: 8px auto;
      padding: 16px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
  .DistrictSelect {
    position: absolute;
    top: 3px;
    width: 350px;
    right: 50px;
  }
  .financialMainFrom {
    ul.nav-tabs {
      display: flex;

      li {
        flex: 0 0 auto;
        background-color: #f0f3f99c;
        border-right: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        &:first-child {
          border-left: 1px solid #dfdfdf;
        }

        .nav-link.active {
          background: var(--sttl-font-secondary);
          color: var(--sttl-font-white);
          border-radius: 0;
          // padding: 15px;
          width: 100%;
          font-size: var(--sttl-font-size-16);
          border: 0;
          position: relative;
          height: 100%;

          &::before {
            content: "";
            margin: 0 0.5em;
            display: inline-block;
            border: 12px solid transparent;
            border-top: 11px solid var(--sttl-font-secondary);
            border-bottom: 0 none;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .nav-link {
          color: #323232;
          font-size: var(--sttl-font-size-16);
          width: 100%;
          padding: 10px 15px;
          border: 0;
          border-radius: 0;
          height: 100%;
        }
      }
    }

    .tab-content {
      border: 1px solid #d1d1d1;
      border-top: 0;
      padding: 10px 27px 0px 27px;
      margin-bottom: 0px;

      .accordion {
        .accordion-item {
          .accordion-button:not(.collapsed) {
            box-shadow: none;
          }

          border: 0;
          padding: 20px 0;
          border-bottom: 1px solid #aaa;

          &:last-child {
            border-bottom: 0;
          }

          h3 {
            button {
              color: var(--sttl-font-secondary);
              font-size: var(--sttl-font-size-23);
              font-weight: 500;
              margin-bottom: 0;
              line-height: 35px;
              padding: 0;
              background: transparent;

              &:focus {
                box-shadow: none;
                border: 0;
              }
            }
          }

          p {
            color: #aaaaaa;
            margin-bottom: 22px;
          }

          .accordion-body {
            padding: 0;
          }
          .selected {
            p {
              color: #696969;
            }
          }
        }
      }

      .RevenueDetailsTable {
        overflow-y: hidden;
        .table > :not(caption) > * > * {
          padding: 10px 6px;
          vertical-align: middle;
          line-height: 1.2;
          font-size: var(--sttl-font-size-14);
          thead {
            border-bottom: 1px solid #323232;
          }
        }
        caption,
        .caption {
          @include blankPara;
          padding: 7px 5px 7px 20px;
          .btnDraft {
            font-size: var(--sttl-font-size-14);
          }
        }
        th {
          color: var(--sttl-font-secondary);
          font-size: var(--sttl-font-size-14);
          font-weight: 600;
          padding: 15px 6px;
          border-bottom: 1px solid #dee2e6;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        tr {
          border-bottom: 1px solid #ccc;

          &.borderThik {
            border-bottom: 2px solid var(--sttl-font-black);
          }

          td {
            padding: 15px 5px;
            border-bottom: 0;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }

            span {
              color: var(--sttl-font-secondary);
              font-size: var(--sttl-font-size-12);
            }

            span.status {
              font-size: var(--sttl-font-size-16);
            }

            ul {
              li {
                font-size: var(--sttl-font-size-14);
              }
            }

            &.blankPara {
              @include blankPara;
              select {
                width: 200px;
              }
              p {
                b {
                  font-size: 16px !important;
                  padding: 0 !important;
                  font-weight: 400;
                  color: #f00;
                }
              }
            }
            &.SoP {
              background: #259be917;
              color: #259be9;
            }

            .fileUpload {
              display: flex;
              align-items: center;
              input[type="file"] {
                position: relative;
                z-index: 2;
                opacity: 0;
                cursor: pointer;
              }

              .customUpload {
                position: absolute;
                top: 0;
                background: #ecf1f9;
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                z-index: 1;
                text-align: center;
                padding: 10px 0;
                height: 40px;
                border-radius: 5px 0 0 5px;
                border: 0;
                span {
                  font-size: var(--sttl-font-size-14);
                  cursor: pointer;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
              .dowupEqule {
                flex: 0 0 auto;
                width: 100%;
                max-width: 70%;
              }
              .downEqule {
                max-width: 100%;
                position: relative;
                .customUpload {
                  width: 100%;
                }
              }
              .viewupload {
                background-color: #c9e4c9;
                flex: 0 0 auto;
                width: 100%;
                max-width: 30%;
                line-height: 40px;
                height: 40px;
                border-radius: 0 5px 5px 0;
                svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }

            input[type="radio"] {
              width: 16px;
              height: 16px;
              min-width: 16px;
              border-radius: 100%;
              padding: 0px;
            }
            input[type="date"] {
              text-align: left !important;
            }
          }
        }

        .activeRow {
          .activeRowSR,
          .activeRowItem {
            color: var(--sttl-font-secondary);
            font-weight: 600;
          }
          // .text-end{
          //     padding-right: 15px!important;
          // }
        }

        input,
        select {
          width: 100%;
          // min-width: 120px;
          height: 40px;
          text-align: center;
          border-radius: 5px;
          border: 1px solid #cccccc;
          padding: 0 15px;
        }

        input[type="checkbox"] {
          height: 22px;
          margin-top: 10px;
        }

        input:disabled,
        input[type="text"]:read-only,
        input[type="date"]:read-only {
          background: #f5f2f2;
          border: 1px solid #cccccc;
          color: #323232;
        }

        .tfooter {
          background: #fbfbfb;
        }

        .btn-warning {
          border-radius: 0;
          background: #f9f2ec;
          border-color: #f9f2ec;
          color: #f1640f;

          &:hover,
          &:active,
          &:focus-visible {
            background: transparent;
            border: 1px solid #f1640f;
            color: #f1640f;
            box-shadow: none;
          }
        }
      }

      .PqTable {
        padding: 0px 0px 29px 0px;

        thead {
          th {
            &:first-child {
              min-width: 75px;
            }

            // font-size: 14px;
          }
        }
        td.text-center.fixWidth {
          width: 260px;
          max-width: 260px;
          min-width: 260px;
        }
        tr {
          &:first-child {
            td {
              // &:nth-child(2) {
              //     min-width: 240px;
              // }

              // &:last-child {
              //     min-width: 200px;
              // }
            }
          }

          td {
            // font-size: 14px;

            input,
            a,
            span {
              font-size: var(--sttl-font-size-12) !important;
            }
          }
        }
      }
      .rdssGuideline {
        .InnerBanner {
          .BreadCumb {
            li {
              &::before {
                content: ">";
                position: absolute;
                right: -3px;
                top: 1px;
                background-color: transparent;
              }
              &:first-child {
                padding-left: 0;
                &::before {
                  display: none;
                }
              }
              &:last-child {
                &::before {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  .Tbnote {
    color: var(--sttl-font-green);
    background-color: #f5fff6;
  }
}
.formBtnSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a,
  button {
    background: #54a800;
    color: var(--sttl-font-white);
    text-decoration: none;
    padding: 11px 27px;
    border-radius: 100px;
    margin-right: 20px;
    border: 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .btnSubmit {
    background-color: #ffb433;
    color: var(--sttl-font-black);
    margin: 0;
  }
}
.btnDraft {
  background-color: var(--sttl-font-secondary);
  @include commonButton;
  &:hover {
    color: var(--sttl-font-white);
  }
}
[aria-hidden="true"] {
  display: none;
}
.swal-button {
  background-color: var(--sttl-font-secondary);
  border: 1px solid var(--sttl-font-secondary);
  &:not([disabled]):hover {
    background-color: transparent;
    color: var(--sttl-font-secondary);
    border: 1px solid var(--sttl-font-secondary);
  }
}
.form-check-input {
  &:checked[type="radio"],
  &.checked {
    background-image: url(../images/radio.svg);
    background-size: 8px;
  }

  &:checked {
    background-color: transparent;
    border-color: #f58216;
  }

  &:focus {
    box-shadow: none;
  }
}

.tooltip-inner {
  max-width: 800px;
  text-align: left;
  // width: 50vw;
  ul {
    padding: 0 20px;
    margin: 10px 0;
    li {
      font-size: var(--sttl-font-size-12);
    }
  }
}

.rdssGuideline {
  .nav-tabs {
    border: 0;
    flex-wrap: nowrap;
    .nav-item {
      width: 100%;
      .nav-link {
        background-color: var(--sttl-font-secondary);
        color: var(--sttl-font-white) !important;
        border-radius: 0;
        padding: 20px 60px;
        width: 100%;
        &.active {
          border-color: var(--sttl-font-primary) !important;
          background-color: var(--sttl-font-primary) !important;
          &::before {
            border-top: 11px solid var(--sttl-font-primary) !important;
          }
        }
        &:hover {
          // color: var(--sttl-font-secondary)!important;
          // border-color: var(--sttl-font-secondary)!important;
          // background-color: transparent!important;
        }
      }
    }
  }
  .tab-content {
    background-color: #f6f6f6;
    padding: 30px;
    h4 {
      font-size: var(--sttl-font-size-26);
      font-weight: var(--sttl-font-bold);
      padding: 0px 0 10px 0;
      color: var(--sttl-font-secondary);
      span {
        color: var(--sttl-font-primary);
      }
    }
    ul {
      padding: 0;
      list-style-type: none;
      li {
        padding-left: 20px;
        margin-bottom: 10px;
        position: relative;
        font-size: var(--sttl-font-size-18);
        font-weight: var(--sttl-font-light);
        &::before {
          content: "";
          background-color: var(--sttl-font-primary);
          width: 8px;
          height: 8px;
          position: absolute;
          left: 0;
          top: 9px;
          border-radius: 100%;
        }
        a {
          color: #4d4d4d;
          text-decoration: none;
          font-size: 16px;
          line-height: 26px;
          margin: 0;
          display: block;
          &:hover {
            color: var(--sttl-font-primary);
          }
          span {
            font-weight: var(--sttl-font-medium);
            font-size: var(--sttl-font-size-14);
          }
          svg {
            margin-top: -4px;
          }
        }
      }
    }
  }
  .accordion-button:focus {
    border: 0;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    color: #f58216;
    background-color: #ffecdb;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
    font-size: var(--sttl-font-size-20);
  }
  .accordion-button:not(.collapsed)::after {
    filter: invert(1);
  }
  .accordion-collapse.collapse.show {
    ul {
      li {
        margin-bottom: 10px;
        a {
          color: var(--sttl-font-secondary);
          font-size: var(--sttl-font-size-16);
          text-decoration: none;
          line-height: 26px;
          display: block;
          margin: 0px 0;
          svg {
            margin-left: 0px;
          }
          &:hover {
            color: var(--sttl-font-primary);
          }
        }
      }
    }
  }
}
.fileUpload {
  display: flex;
  align-items: center;
  input[type="file"] {
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .customUpload {
    position: absolute;
    top: 0;
    background: #fff;
    border: 1px solid #cccccc;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    text-align: center;
    padding: 10px 0;
    height: 40px;
    border-radius: 5px;
    span {
      font-size: var(--sttl-font-size-14);
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .dowupEqule {
    flex: 0 0 auto;
    width: 100%;
    max-width: 70%;
  }
  .downEqule {
    max-width: 100%;
    .customUpload {
      width: 100%;
    }
  }
  .viewupload {
    background-color: #c9e4c9;
    flex: 0 0 auto;
    width: 100%;
    max-width: 30%;
    line-height: 40px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
.sanctionLetterMainForm {
  .sanctionLetterYearNone {
    display: none;
  }
}
.financialForm .financialMainFrom .tab-content {
  .pqSopFormOnly {
    &.table-responsive {
      max-height: unset !important;
    }
    & > table {
      // width: 1500px;
      tbody {
        tr {
          border-bottom: 0 !important;
          &.borderThik {
            border-bottom: 2px solid var(--sttl-font-black) !important;
          }
          td {
            font-size: 14px;
            width: 218px !important;
            // padding: 10px 5px !important;
          }
          // &:nth-child(2){
          //     td{
          //         width: 300px!important;
          //         &:first-child{
          //             width: auto!important;
          //         }
          //     }
          // }
        }
      }
    }
  }
}

body {
  .financialForm .financialMainFrom {
    .table-responsive {
      overflow-y: auto !important;
      max-height: 80vh;
      table {
        thead {
          th {
            position: sticky;
            top: 0;
            z-index: 9;
            background-color: #fff;
          }
        }
        tbody {
          tr {
            td {
              input,
              span {
                font-size: 12px !important;
                padding: 0 5px !important;
              }
              select {
                font-size: 12px !important;
                padding: 0 25px 0 5px !important;
              }
              input[data-amount="Y"] {
                text-align: right !important;
              }
              .form-select {
                background-position: right 6px center;
              }
            }
          }
        }
      }
    }
  }
  .financialForm .financialMainFrom {
    .table-responsive.RevenueDetailsTable.actionHistory {
      max-height: 60vh;
    }
  }
  // .financialForm .financialMainFrom{
  //     .misreportTable{
  //         overflow-y: hidden!important;
  //         max-height: 85vh;
  //     }
  // }
}
.modal-content table thead th {
  background: var(--sttl-font-secondary) !important;
  position: sticky !important;
  top:0;
  background-color: #fff;
}
.ref-table .activeRowItem {
  min-width: 360px;
}
.customStepper {
  .StepButton-0-2-4 {
    border-radius: 100%;
    border: 0;
  }
  .StepButtonContent-0-2-5.active {
    color: #fff;
  }
  .ConnectorContainer-0-2-14 {
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
    .Connector-0-2-15 {
      display: block;
      border-color: rgb(189, 189, 189);
      border-top-style: solid;
      border-top-width: 1px;
    }
  }
}
.StepperContainer-0-2-1 {
  .StepButton-0-2-4 {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
  }

  .StepButton-0-2-4.active {
    background-color: var(--sttl-font-secondary);

    &:hover {
      background-color: #4f71d1;
    }

    & + div > span {
      color: var(--sttl-font-secondary);
      font-weight: 600;
    }
  }

  .StepButton-0-2-4.completed {
    background-color: var(--sttl-font-green);
    span {
      color: #fff;
    }
    & + div > span {
      color: var(--sttl-font-green);
      font-weight: 500;
    }

    &:hover {
      background-color: var(--sttl-font-green);
    }
  }

  .StepButton-0-2-4 + div > span {
    font-size: var(--sttl-font-size-20);
    color: #848484;
    font-weight: 300;
    margin-top: 0px;
    line-height: 20px;
  }
}
.StepperContainer-0-2-1 {
  display: flex;
  padding: 24px 0;
  align-items: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  flex-direction: row;
}
.StepContainer-0-2-2 {
  flex: 1;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
}
.StepMain-0-2-3 {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.page-item {
  input {
    border-radius: 0;
    border-color: var(--sttl-font-primary);
    text-align: center;
    border-right: 0;
    border-left: 0;
  }
}
.page-item:first-child .page-link {
  border-radius: 0;
  background: var(--sttl-font-primary);
  color: #fff;
  border-color: var(--sttl-font-primary);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
  color: var(--sttl-font-primary);
  border-color: var(--sttl-font-primary);
}
.page-item:last-child .page-link {
  border-radius: 0;
  background: var(--sttl-font-primary);
  color: #fff;
  border-color: var(--sttl-font-primary);
}
.tdInnerTable {
  .table {
    thead th {
      font-size: 14px !important;
    }
    td {
      input,
      .view-field.text-end {
        font-size: 14px !important;
      }
    }
  }
}
.PqTable thead th {
  font-size: 14px !important;
}
.mw-30 {
  max-width: 30px !important;
}
.misreportTable table tr {
  td {
    text-align: center;
    &:first-child,
    &:nth-child(2) {
      text-align: left;
    }
  }
  .discom_name,
  .mistitle1,
  .mistitle2 {
    text-align: left !important;
  }
  mark {
    padding: 0;
  }
  .missantion_cost {
    text-align: center !important;
  }
}
.modal-dialog.modal-dialog-centered {
  .rdssGuideline {
    .tab-content {
      background: transparent;
      border: 0;
    }
    ul.nav.nav-tabs {
      border-bottom: 1px solid #d8d8d8;
      li.nav-item {
        width: 20%;
      }
    }
  }
}
.docCenterFolderModalBox {
  .modal-dialog {
    max-width: 400px !important;
    .modal-title {
      font-size: 18px;
    }
  }
}
.docCenterFileModalBox {
  .modal-dialog {
    max-width: 500px !important;
    .modal-title {
      font-size: 18px;
    }
  }
}
.UploadModalCustomUpload {
  // width: 200px;
  .downEqule {
    height: 100px !important;
    input {
      height: 100px !important;
    }
    .customUpload {
      height: 100px !important;
      padding: 15px 0 !important;
    }
  }
  & + .btn-success {
    width: 200px;
    & + .progressWidth {
      width: 200px;
      .bg-success {
        --bs-bg-opacity: 1;
        background-color: rgba(#54a800, var(--bs-bg-opacity)) !important;
      }
    }
  }
}
.dmsCenter {
  .selected {
    background-color: #f5821626;
  }

  .unselected {
    background-color: unset;
  }
}
.rdssGuideline .tab-content ul.fileUl li {
  font-size: var(--sttl-font-size-12) !important;
  margin-bottom: 5px;
  padding-left: 15px;
  &:before {
    top: 0;
    margin-top: 4px !important;
    width: 6px;
    height: 6px;
  }
  .deleteFile {
    top: 0;
    margin-top: -3px !important;
    margin-left: 1px;
    cursor: pointer;
  }
}
#panel-DocumentCentre {
  .addFolderBtn {
    padding: revert;
    svg {
      font-size: 35px;
    }
    .fileUploadBtn {
      padding: 2px;
    }
  }
}
td.ref-total {
  color: #fff;
}
.uploadSelect {
  .downEqule,
  .dowupEqule {
    display: flex;
    .customUpload {
      width: 50%;
    }
    select.form-control {
      width: 50%;
    }
  }
  .viewupload {
    width: 40px;
    margin-left: 20px;
    border-radius: 5px;
  }
}
.refUploadSelect {
  .fileUpload {
    justify-content: center;
    .downEqule,
    .dowupEqule {
      display: flex;
      input {
        width: 65px !important;
      }
      .customUpload {
        max-width: 40px;
        border-radius: 5px !important;
        max-height: 36px;
      }
      select.form-control {
        min-width: 100px;
        max-height: 36px;
        margin-right: 5px;
      }
    }
    .viewupload {
      width: 40px !important;
      max-height: 36px;
      border-radius: 5px !important;
      line-height: 32px !important;
      position: relative;
      top: -4px;
    }
  }
}
.docCenter {
  overflow: hidden;
  img {
    width: 85px;
    object-fit: scale-down;
  }
}
.DistrictSelect {
  .uploadSelect {
    .fileUpload {
      justify-content: end;
    }
    input {
      width: 80px !important;
    }
    .customUpload {
      width: 40px !important;
    }
  }
}
.form-control:focus {
  box-shadow: none;
}

// New UI
.financialMainFrom.inputFromNew,
.mainWrap.inputFromNew {
  position: relative;
  .nav.nav-tabs.row.gx-0.tabNavigation.border-0 {
    li {
      border-bottom: 1px solid #dfdfdf;
    }
  }
  .tab-content {
    .accordion-item {
      position: relative;
      .col-md-2.float-end {
        position: absolute;
        top: 20px;
        right: 0;
        width: 50px;
        z-index: 99;
        .downEqule {
          input[type="file"] {
            height: 35px;
          }
          .customUpload {
            height: 35px;
            line-height: 35px;
            padding: 0;
          }
        }
        & + h3.accordion-header .accordion-button {
          padding-right: 80px;
        }
      }
      h3.accordion-header {
        .accordion-button {
          font-size: var(--sttl-font-size-18) !important;
        }
      }
    }
    .formBox {
      background: #ecf1f9;
      padding: 15px 10px;
      label {
        font-size: 14px;
        color: var(--sttl-font-secondary);
      }
      select,
      input {
        font-size: 14px;
      }
    }

    .RevenueDetailsTable {
      tr {
        td {
          .fileUpload {
            justify-content: center;
            width: 150px;
            .downEqule,
            .dowupEqule {
              display: flex;
              .customUpload,
              input {
                width: 40px;
                border-radius: 0;
              }
              select {
                width: 71px;
                border-radius: 0;
              }
            }
            .viewupload {
              width: 40px;
              border-radius: 0 !important;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .radioTitleSection {
    margin-top: 20px;
    border-bottom: 2px solid #ced3df;
    .radioYear {
      padding: 0;
      margin-top: 0;
      border: 0;
      .form-check {
        border-right: 1px solid #ced3df;
        padding-left: 20px !important;
        padding: 10px 0;
        display: flex;
        align-items: center;
        label {
          font-size: var(--sttl-font-size-16);
        }
        input[type="radio"] {
          margin: 0 10px 0 0;
        }
        &.last {
          border: 0;
        }
      }
      &.multipleRow {
        padding: 10px 0 !important;
        .form-check {
          border-right: 0 !important;
          padding: 0;
          padding-left: 10px !important;
        }
      }
    }
    .fYearMonth {
      .selectYear {
        width: 100px;
      }
      .inputBtn {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        line-height: 37px;
      }
      a.pdfIcn {
        border-radius: 5px;
        padding: 7px 0;
        background: transparent;
        border: 1px solid #aaa;
        margin-left: 10px;
        width: 40px;
        display: inline-block;
        margin-top: 0;
        text-align: center;
        img {
          height: 24px;
        }
      }
      select {
        font-size: 14px;
      }
      .year.resYear {
        label {
          font-size: 14px;
        }
      }
    }
  }
  .fYearMonth.setPosition {
    select {
      font-size: 14px;
      height: 40px;
    }
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    button.inputBtn {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      line-height: 30px;
      background: var(--sttl-font-secondary);
      text-align: center;
      position: relative;
      color: #fff;
    }
  }
  .tabNavigation {
    justify-content: center;
    margin-top: 20px;
    // position: absolute;
    // width: 100%;
    // max-width: 30%;
    // top: 0;
    // margin: 0;
    // right: 0;
    // justify-content: end;
    // li, li .nav-link.active{
    //     height: 46px;
    //     button{
    //         height: 46px;
    //         padding: 0!important;
    //     }
    // }
  }
  .StepperContainer-0-2-1,
  .stepper-container {
    padding: 20px 0;
    .StepButton-0-2-4,
    .step-button {
      width: 30px;
      max-width: 30px;
      height: 30px;
      max-height: 30px;
      margin-bottom: 5px;
      span {
        font-size: var(--sttl-font-size-14);
      }
      & + div > span {
        font-size: var(--sttl-font-size-16);
      }
    }
    .step-label-content {
      font-size: var(--sttl-font-size-16);
    }
  }
  .pageMiddleTitle {
    margin: 20px 0 10px;
    border-bottom: 2px solid #ced3df;
    h5 {
      font-size: var(--sttl-font-size-18);
      background: #a6bee136;
      padding: 13px 20px;
      display: inline-block;
      margin-bottom: 0;
      color: var(--sttl-font-secondary);
    }
    .form-field.uploadSelect {
      margin-right: 0 !important;
      .fileUpload {
        justify-content: end;
        .downEqule {
          .customUpload,
          input[type="file"] {
            width: 40px;
            margin-right: 10px;
          }
          select.form-control {
            width: calc(100% - 50px);
            height: 40px;
          }
        }
        .viewupload {
          margin-left: 10px;
        }
      }
    }
  }
  .uploadSelect {
    .fileUpload {
      .downEqule {
        width: auto !important;
        input,
        .customUpload {
          width: 40px;
          max-width: 40px;
          margin-right: 10px;
        }
        select {
          width: 150px !important;
          max-width: 150px;
        }
      }
      .viewupload {
        margin-left: 10px !important;
      }
    }
  }
}
.aboutAccordioin {
  .Accordion-group {
    .Tab-panel {
      .actionWrap.energyActionBox {
        .ActionCenterSection {
          .row.gx-0 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            .col-lg-3.col-3.d-flex.justify-content-center {
              width: 100%;
              .tabPanelInner {
                img {
                  height: 60px;
                  filter: invert(1);
                }
                padding: 60px 0px;
                h5 {
                  font-size: 16px;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.energy.input-form.input-energy {
  .financialMainFrom.inputFromNew {
    .table-responsive.RevenueDetailsTable {
      table.table {
        tr {
          td {
            .col-md-0 {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.inputDeclaration {
  h6 {
    color: var(--sttl-font-secondary);
    text-align: justify;
    margin-bottom: 20px;
  }
  label {
    font-size: 14px;
    color: var(--sttl-font-secondary);
    margin-bottom: 5px;
  }
  input,
  select,
  textarea {
    height: 35px;
  }
  .fileUpload {
    .downEqule,
    .dowupEqule {
      display: flex;
      .customUpload,
      input {
        width: 40px;
        height: 35px;
        line-height: 35px;
        border-radius: 0;
        padding: 0;
      }
      select {
        width: 100px;
        border-radius: 0;
      }
    }
    .viewupload {
      width: 40px;
      padding: 0;
      height: 35px;
      line-height: 35px;
      border-radius: 0;
    }
  }
}
.view-field {
  background: #efefef;
  padding: 10px;
  min-height: 39px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
}
.form-group.file {
  .form-field {
    display: flex;
    justify-content: center;
    .view-form-field.text-center {
      width: calc(100% - 50px);
    }
    a.viewupload {
      width: 40px;
      background-color: #c9e4c9;
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      border-radius: 5px !important;
      margin-left: 10px;
    }
  }
}

.modal .rdssGuideline {
  .fileUpload {
    min-width: 100%;
    width: 100% !important;
    .downEqule {
      min-width: 100%;
      width: 100% !important;
      input,
      .customUpload {
        min-width: 100%;
        width: 100% !important;
      }
    }
  }
  form {
    input {
      min-width: 100%;
      width: 100% !important;
    }
  }
}

.award-table {
  height: 500px;
  overflow-y: auto;
}
// .award-table thead { position:absolute;background: #fff; }
.multiple-award-items tbody tr {
  border: none !important;
}
.award-margin-top {
  height: 93px;
}
.boq-margin-top {
  height: 72px;
}
.multiple-award-items {
  padding: 0px !important;
}
.award-table select {
  font-size: 12px;
}
.award-footer-table tbody tr {
  border: none !important;
}

.col-md-2.float-end {
  width: 70px !important;
}

.accordion-body {
  .accordion {
    .accordion-header .accordion-button {
      padding: 10px;
      font-size: 16px;
    }
  }
}

.nodaFolder {
  .docCenter {
    padding: 15px 10px !important;
    transition: 0.3s all ease-out;
    h6 {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-bottom: 0 !important;
      border-bottom: 0;
    }
    &:hover {
      box-shadow: 0 0 20px #dfdfdf;
    }
  }
  h5 {
    color: var(--sttl-font-secondary);
  }
  svg {
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .excel {
    background: #008000;
  }
  .pdf {
    background: #ff281d;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}

.award-table table.multiTableTD thead {
  position: unset;
}
table.multiTableTD td.td11,
table.lrMultipleID td.td6,
table.lrMultipleID td.td8,
table.lrMultipleID td.td9,
table.lrMultipleID td.td10,
table.lrMultipleID td.td11,
table.lrMultipleID td.td12,
table.lrMultipleID td.td13,
table.siMultipleID td.td5,
table.siMultipleID td.td6,
table.siMultipleID td.td7,
table.siMultipleID td.td8,
table.siMultipleID td.td9,
table.siMultipleID td.td10,
table.siMultipleID td.td11,
table.siMultipleID td.td12,
table.siMultipleID td.td13,
table.siMultipleID td.td14,
table.siMultipleID td.td15,
table.lrMultipleID td.td14,
table.lrMultipleID td.td17 {
  min-width: 100px;
  width: 100px;
}
table.lrMultipleID td.td7 {
  min-width: 120px;
  width: 120px;
}
table.multiTableTD td.td12,
table.multiTableTD td.td13,
table.siMultipleID td.td2 {
  min-width: 200px;
  width: 200px;
}
table.lrMultipleID th span,
table.siMultipleID td span {
  display: block;
}
table.lrMultipleID td.td2 {
  min-width: 180px;
  width: 180px;
}
table.lrMultipleID td.td3,
table.lrMultipleID td.td4,
table.lrMultipleID td.td5,
table.siMultipleID td.td3,
table.siMultipleID td.td4 {
  min-width: 150px;
  width: 150px;
}
table.lrMultipleID td.td1,
table.siMultipleID td.td1 {
  min-width: 50px;
  width: 50px;
}
.form-field select.form-select {
  padding-right: 25px !important;
  background-position: right 0.4rem center !important;
}

tbody.progressDynWidth td.multiple-award-items td {
  min-width: 100px;
  width: 100px !important;
}
tbody.progressDynWidth td.multiple-award-items td.td22,
tbody.progressDynWidth td.multiple-award-items td.td23,
tbody.progressDynWidth td.multiple-award-items td.td24 {
  max-width: 15px;
  min-width: 15px;
}
tbody.awardItemDynWidth {
  td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  td.td2,
  td.td3,
  td.td4,
  td.td5,
  td.td6,
  td.td7,
  td.td8 {
    min-width: 150px;
    width: 150px;
  }
  td.multiple-award-items {
    padding: 0 !important;
    td.td11,
    td.td12,
    td.td13,
    td.td14,
    td.td15,
    td.td16,
    td.td17,
    td.td18 {
      min-width: 150px;
      width: 150px;
    }
    td {
      &:last-child {
        width: 20px;
        max-width: 20px;
        min-width: 20px;
      }
    }
  }
}

tbody.awardItemDynWidth {
  td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  td.td2,
  td.td3,
  td.td4,
  td.td5,
  td.td6,
  td.td7,
  td.td8,
  td.td10 {
    min-width: 150px;
    width: 150px;
  }
  td.multiple-award-items {
    padding: 0 !important;
    td.td11,
    td.td12,
    td.td13,
    td.td14,
    td.td15,
    td.td16,
    td.td17,
    td.td18 {
      min-width: 150px;
      width: 150px;
    }
    td {
      &:last-child {
        width: 20px;
        max-width: 20px;
        min-width: 20px;
      }
    }
  }
}
tbody.dynaWidthSubstation {
  td.multiple-award-items {
    td.td11,
    td.td13,
    td.td14,
    td.td16,
    td.td17,
    td.td18,
    td.td19,
    td.td20,
    td.td21,
    td.td22,
    td.td23,
    td.td24,
    td.td25,
    td.td26,
    td.td27,
    td.td28,
    td.td29,
    td.td30,
    td.td31 {
      width: 100px;
      min-width: 100px;
    }
  }
  select {
    font-size: 12px !important;
  }
}
tbody.dynaWidthIto {
  td.multiple-award-items {
    td.td19 {
      width: auto;
      min-width: unset;
    }
  }
}
.praapti .radioYear {
  height: auto !important;
}
body table .dynaWidthSM .multiple-award-items {
  td.td18,
  td.td20,
  td.td21,
  td.td22,
  td.td23,
  td.td24,
  td.td25,
  td.td26,
  td.td27,
  td.td28,
  td.td29,
  td.td30,
  td.td31,
  td.td32,
  td.td33,
  td.td34,
  td.td35,
  td.td36,
  td.td37,
  td.td38 {
    width: 95px;
    min-width: 95px;
  }
}
body table .dynaWidthSMLR .multiple-award-items {
  td.td16,
  td.td17,
  td.td18,
  td.td19,
  td.td20,
  td.td21,
  td.td22,
  td.td23,
  td.td24,
  td.td25,
  td.td26,
  td.td27,
  td.td28,
  td.td29,
  td.td30,
  td.td31,
  td.td32,
  td.td33,
  td.td34 {
    width: 95px;
    min-width: 95px;
  }
}
body table .dynaWidthPDSM .multiple-award-items {
  td.td17,
  td.td21,
  td.td18,
  td.td20,
  td.td22 {
    width: 82px;
    min-width: 82px;
  }
  td.td25 {
    width: 100px;
    min-width: 100px;
  }
  td.td23 {
    width: 115px;
    min-width: 115px;
  }
  td.td24,
  td.td27 {
    width: 112px;
    min-width: 75px;
  }
  td.td26 {
    width: 90px;
    min-width: 90px;
  }
  td.td28 {
    width: 110px;
    min-width: 110px;
  }
  td.td29 {
    width: 75px;
    min-width: 60px;
  }
}
.preAstatus {
  h6 {
    color: var(--sttl-font-secondary);
    font-size: 16px;
  }
  table {
    width: 100%;
    vertical-align: middle;
    font-size: 14px;
    input {
      padding: 0 10px;
    }
    input,
    select {
      height: 35px;
      border-radius: 5px;
      border: 1px solid #aaa;
      font-size: 14px !important;
      &:focus {
        box-shadow: none;
      }
      &:focus-visible {
        outline: none;
      }
    }
    td {
      padding: 0 10px;
    }
    .fileUpload {
      .downEqule {
        max-height: 40px;
        .customUpload {
          position: absolute;
          top: 0;
          background: #ecf1f9;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          z-index: 1;
          text-align: center;
          padding: 7px !important;
          max-height: 40px;
          border-radius: 5px 0 0 5px;
          border: 0;
          font-size: 0;
        }
        input {
          max-height: 40px;
          width: 40px;
        }
      }
      select {
        height: 40px;
        border-radius: 0px 5px 5px 0px;
        width: 120px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  table.rowWidth tr td {
    padding-bottom: 15px;
  }
  a.addMoreBtn {
    text-decoration: none;
    background-color: var(--sttl-font-secondary);
    color: #fff;
    padding: 10px 20px;
    display: block;
    border-radius: 20px;
    margin: 30px auto;
    width: 150px;
    text-align: center;
  }
  .table-responsive {
    padding: 20px 0;
  }
}

@media (max-width: 1366px) {
  .preAstatus {
    .radioYear {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      h6 {
        font-size: 12px;
      }
    }
    .table-responsive {
      padding: 15px 0;
    }
    table {
      font-size: 10px;
      input,
      select {
        font-size: 10px !important;
        height: 30px;
      }
      .fileUpload {
        .downEqule {
          max-height: 30px;
          .customUpload {
            max-height: 30px;
            padding: 2px !important;
            svg {
              height: 12px;
            }
          }
          input {
            max-height: 30px;
          }
        }
        select {
          height: 30px;
        }
      }
    }
    a.addMoreBtn {
      font-size: 12px;
      width: 120px;
    }
    table.rowWidth {
      input,
      select {
        max-width: 120px;
      }
    }
  }
}

textarea {
  font-size: 10px !important;
  padding: 5px 5px !important;
}
.fixedcol1 {
  position: absolute;
  left: 0;
  top: auto;
  border-right: 0px none black;
  border-top-width: 3px; /*only relevant for first row*/
  margin-top: -3px; /*compensate for top border*/
}
.fixedcol2 {
  position: absolute;
  left: 0;
  top: auto;
  border-right: 0px none black;
  border-top-width: 3px; /*only relevant for first row*/
  margin-top: -3px; /*compensate for top border*/
}
textarea[readonly] {
  background-color: #f5f2f2 !important;
  border: 1px solid #cccccc;
  color: #323232;
}

.financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable
  .modal-body
  .table
  thead
  th
  button {
  background: transparent !important;
}

.financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable
  tbody.lossReductionAwardLastColDynWidth
  tr
  td.td10 {
  min-width: 145px;
  width: 145px;
  max-width: 145px;
}
.financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable
  tbody.lossReductionAwardLastColDynWidth
  tr
  td.td11 {
  min-width: 130px;
  width: 130px;
  max-width: 130px;
}
.financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable
  tbody.lossReductionAwardLastColDynWidth
  tr
  td.td12 {
  min-width: 140px;
  width: 140px;
  max-width: 140px;
}
.financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable
  tbody.lossReductionAwardLastColDynWidth
  tr
  td.td13 {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}
.financialForm .financialMainFrom .tab-content .RevenueDetailsTable{
  .reWidthSet{
    table{
      tbody{
        tr{
          vertical-align: top;
          td.td10, td.td18, td.td19, td.td20, td.td21, td.td22, td.td23, td.td24, td.td25, td.td26, td.td27, td.td28, td.td29, td.td30, td.td31, td.td32, td.td33, td.td34, td.td35{width: 95px;max-width: 95px;min-width: 95px;padding: 15px 5px!important;}
          td.td17{width: 180px;max-width: 180px;min-width: 180px;padding: 15px 5px!important;}
        }
      }
    }
  }
  .reWidthSetup{
    table{
      tbody{
        tr{
          td.td17{width: 180px;max-width: 180px;min-width: 180px;}
          td.td10, td.td18, td.td20, td.td21, td.td22{width: 95px;max-width: 95px;min-width: 95px;}
          td.td23, td.td28{width: 130px;max-width: 130px;min-width: 130px;}
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .financialForm .financialMainFrom .tab-content .RevenueDetailsTable{
    .reWidthSet{
      table{
        tbody{
          tr{
            vertical-align: top;
            td.td10, td.td18, td.td19, td.td20, td.td21, td.td22, td.td23, td.td24, td.td25, td.td26, td.td27, td.td28, td.td29, td.td30, td.td31, td.td32, td.td33, td.td34, td.td35{padding: 0px 3px!important;}
            td.td17{padding: 0px 3px!important;}
          }
        }
      }
    }
    .reWidthSetup{
      table{
        tbody{
          tr{
            td.td10, td.td18, td.td19, td.td20, td.td21, td.td22, td.td23, td.td24, td.td25, td.td26, td.td27, td.td28, td.td29, td.td30, td.td31, td.td32, td.td33, td.td34, td.td35{padding: 0px 3px!important;}
            td.td17{padding: 0px 3px!important;}
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1280px;
  }
}
@media (min-width: 1400px) {
  body .container {
      max-width: 1416px;
      width: 100%;
  }
}
.btn-outline-primaryDark{
  border-color: var(--sttl-font-secondary);
  color: var(--sttl-font-secondary);
  transition: 0.4s all ease-out;
  &:hover, &:active, &:focus-visible{
    border-color: var(--sttl-font-secondary)!important;
    background-color: var(--sttl-font-secondary)!important;
    color:#fff!important;
  }
}