.app-rdss-feeder-progress {
  .label-h {
    font-weight: 600 !important;
    color: var(--sttl-font-secondary);
    font-size: var(--sttl-font-size-14);
  }

  .content-table {
    table {
      border-collapse: separate;
      border-spacing: 0;
    }

    table th {
      border-bottom: 1px solid #dee2e6;
      border-right: 0px;
    }

    table tr:first-child th {
      border-top: 1px solid #dee2e6;
    }

    table td {
      border-bottom: 1px solid #dee2e6 !important;
      border-right: 0px;
    }

    table tr:first-child td {
      border-top: 1px solid #dee2e6;
    }

    table th:first-child,
    table td:first-child {
      border-left: 1px solid #dee2e6;
    }

    table th:last-child,
    table td:last-child {
      border-right: 1px solid #dee2e6;
    }
  }

  .financialForm .financialMainFrom .tab-content .RevenueDetailsTable tr td .field-comp-select-search [class$="-indicatorSeparator"] {
    padding: 0px !important;
  }
}