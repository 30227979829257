.sidebarSection {
    background-color: var(--sttl-font-white);
    border-radius: 14px;
    box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04);
    padding: 20px 9px;
    text-align: center;
    height: 100%;
    a.backBtn {
        color: rgba(27, 47, 104, 1);
        padding-bottom: 14px;
        display: block;
        border-bottom: 1px solid rgba(225, 222, 222, 1);
        margin-bottom: 14px;
        svg {
            width: 28px;
            height: 30px;
        }
    }

    ul {
        li {            
            margin-bottom: 14px;
            a{               
                padding: 16px;
                border-radius: 14px;
                display: block;
                svg {
                    color: rgba(170, 170, 170, 1);
                    width: 28px;
                    height: 30px;
                }
            }
        }
        .active {
            background: rgba(242, 242, 242, 1);
            svg {
                color: var(--sttl-font-primary);
            }
        }

    }
}