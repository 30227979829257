:root{   
    --sttl-font-size-10: 0.625rem;
    --sttl-font-size-11: 0.6875rem;
    --sttl-font-size-12: 0.75rem;
    --sttl-font-size-13: 0.8125rem;
    --sttl-font-size-14: 0.875rem;
    --sttl-font-size-16: 1rem;
    --sttl-font-size-18: 1.125rem;
    --sttl-font-size-20: 1.25rem;
    --sttl-font-size-22: 1.375rem;
    --sttl-font-size-24: 1.5rem;
    --sttl-font-size-26: 1.625rem; 
    --sttl-font-size-28: 1.75rem; 
    --sttl-font-size-30: 1.875rem; 
    --sttl-font-size-32: 2rem;
    --sttl-font-size-34: 2.125rem;
    --sttl-font-size-36: 2.25rem;
    --sttl-font-size-38: 2.375rem;
    --sttl-font-size-40: 2.5rem;
    --sttl-font-size-42: 2.625rem;
    --sttl-font-size-44: 2.75rem;
    --sttl-font-size-46: 2.875rem;
    --sttl-font-size-48: 3rem;
    --sttl-font-size-50: 3.125rem;
    --sttl-font-size-52: 3.25rem;
    --sttl-font-size-54: 3.375rem;
    --sttl-font-size-56: 3.5rem;
    --sttl-font-size-58: 3.625rem;
    --sttl-font-size-60: 3.75rem;
}
@import '../../../../css/mixin';
header{
    background-color: var(--sttl-font-white);
    box-shadow: 0 10px 41px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1;
    .topHeader{
        background-color: #F5F5F5;
        ul{
            padding: 0;
            margin:0;
            li{
                display: inline-block;
                position: relative;
                padding: 0px 10px;
                &::before{
                    content: "";
                    width: 1px;
                    height: 10px;
                    background: #C4C4C4;
                    position: var(--sttl-position-absolute);
                    right: 0;
                    top:54%;
                    transform: translateY(-50%);
                }
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                    &:before{
                        display: none;
                    }
                }
                span.searchBtn{
                    font-size: 0;
                }
                a{
                    color:#323232;
                    text-decoration: none;
                    @include customFont(13px);
                    line-height: 27px;
                    font-weight: 300;
                }
                a.lightMode, a.darkMode{
                    border: 1px solid #989898;
                    background: var(--sttl-font-white);
                    border-radius: 100%;
                    display: inline-block;
                    font-size: 0!important;
                    width: 10px;
                    height: 10px;
                    line-height: 18px;
                }
                a.darkMode{
                    background-color: var(--sttl-font-black);
                }            
            }
        }
    }
    .mainHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .logo {
            .dark-theme{
                display: none;
            }
            img {
                padding-right: 24px;
            }
        }
        .navMenu{ 
            ul.navbar-nav{
                li.nav-item{
                    a.nav-link {
                        color: #555555;
                        font-size: var(--sttl-font-size-16);
                        padding: 8px 15px;
                        font-weight: var(--sttl-font-medium);
                        svg{
                            color:var(--sttl-font-primary);
                            width: 21px;
                            height: 21px;
                            margin-top: -5px;
                        }
                    } 
                    &:hover{
                        a.nav-link{
                            color: var(--sttl-font-primary);
                        }
                    }               
                    .loginBtn{                    
                        a.nav-link{
                            padding: 0;
                            color: var(--sttl-font-primary);
                            transition: 0.3s all ease-out;
                            border: 1px solid var(--sttl-font-primary);
                            border-radius: 5px;
                            padding: 5px 15px;
                            &:hover{
                                background-color: var(--sttl-font-primary);
                                color:var(--sttl-font-white);
                            }
                        }
                        .userProfile {
                            display: flex;
                            align-items: center;
                            .userIcon {
                                margin-right: 10px;
                            }                                    
                            .userName {
                                .dropdown {
                                    line-height: 14px;
                                    .dropdown-toggle::after {
                                        display: inline-block;
                                        margin: 0 10px;
                                        vertical-align: 2px;
                                        content: "";
                                        border-top: 10px solid transparent;
                                        border-right: 0;
                                        border-bottom: 0;
                                        border-left: 10px solid black;
                                        width: 9px;
                                        height: 9px;
                                        border-top: 2px solid #F58216;
                                        border-left: 2px solid #F58216;
                                        transform: rotate(-136deg);
                                    }
                                    .dropdown-menu.show {
                                        display: block;
                                        border-radius: 0;
                                        margin-top: 5px;
                                        inset: 0px auto auto -36px!important;
                                        .dropdown-item{
                                            font-size: var(--sttl-font-size-14);
                                            color: #323232;
                                        }
                                    }
                                }
                                span{
                                    color: #848484;
                                    font-size: var(--sttl-font-size-12);
                                }
                                
                                button#dropdown-basic {
                                    border: 0;
                                    padding: 0;
                                    background: transparent;
                                    color: #323232;
                                    font-size: var(--sttl-font-size-14);
                                    line-height: 14px;
                                }
                            }                                
                        }
                    }
                }
            }
        }
    }
    .offcanvas.searchSlide{
        margin-bottom: 50px;
        background-color: var(--sttl-font-white);
        .offcanvas-body{
            display: flex;
            align-items: center;
            justify-content: center;
            .offCanvasSearch{
                .search-box {
                    width: 350px;
                    position: relative;
                    display: flex;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    .search-input {
                        width: 100%;
                        font-size: var(--sttl-font-size-16);
                        padding: 20px 30px;
                        background-color: #eaeaeb;
                        color: #6c6c6c;
                        border-radius: 50px;
                        border: none;
                        transition: all .4s;
                        &:focus{
                            border:none;
                            outline:none;
                            box-shadow: 0 1px 12px #b8c6db;
                            -moz-box-shadow: 0 1px 12px #b8c6db;
                            -webkit-box-shadow: 0 1px 12px #b8c6db;
                        }
                    }
                    .search-btn {
                        background-color: transparent;
                        font-size: var(--sttl-font-size-18);
                        padding: 6px 9px;
                        margin-left:-45px;
                        border:none;
                        color: #6c6c6c;
                        transition: all .4s;
                        z-index: 10;
                        &:hover{
                            transform: scale(1.2);
                            cursor: pointer;
                            color: var(--sttl-font-black);
                        }
                        &:focus{
                            outline:none;
                            color:var(--sttl-font-black);
                        }
                    }
                }
                .btn-close{
                    background-color: var(--sttl-font-primary);
                    opacity: 1;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    margin-left: 20px;
                }
            }
        }
    }
}

.dark-mode header{
    li.nav-item .loginBtn a.nav-link{
        color: #80ff00 !important;
        border-color: #80ff00!important;
        &:hover{
            background-color: #80ff00!important;
            color: #000!important;
        }
    }
}