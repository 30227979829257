.app-rdss-cb {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  .label-h {
    font-weight: 600 !important;
    color: var(--sttl-font-secondary);
    font-size: var(--sttl-font-size-14);
  }

  .content-table {
    table {
      border-collapse: separate;
      // border-collapse: collapse;
      border-spacing: 0;
    }

    table th {
      border-bottom: 1px solid #dee2e6;
      border-right: 0px;
    }

    table tr:first-child th {
      border-top: 1px solid #dee2e6;
    }

    table td {
      border-bottom: 1px solid #dee2e6 !important;
      border-right: 0px;
    }

    table tr:first-child td {
      border-top: 1px solid #dee2e6;
    }

    table th:first-child,
    table td:first-child {
      border-left: 1px solid #dee2e6;
    }

    table th:last-child,
    table td:last-child {
      border-right: 1px solid #dee2e6;
    }
  }

  .app-rdss-qm-report {
    .financialForm .radioYear {
      height: auto;
      .form-check {
        padding-left: inherit !important;
      }
    }
  }

  .visit-image-viewer,
  .seal-image-viewer {
    [role="dialog"] {
      background-color: rgba(0, 0, 0, 0.8) !important;
    }

    [aria-hidden="true"] {
      display: block;
    }
  }
}

.card {
  border: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .card-img{
    height: 220px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  ul{
    padding: 20px;
    li {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      span {
        font-weight: 500;
        color: var(--sttl-font-secondary);
      }
    }
  }
  .ediBtn {
    position: absolute;
    top: 0;
    right: 0;
    background: #198754;
    color: #fff;
    box-shadow: -3px 5px 10px rgba(0, 0, 0, 0.18);
    border-radius: 0 0 0 8px;
    padding: 5px 10px;
  }
  .buttonBox {
    padding: 5px 20px;
    display: flex;
    gap: 10px;
    justify-content: end;
    margin-top: 10px;
    a.downloadBtn, a.btn-outline-danger, a.btn-outline-success{
      font-size: 14px;
      border-radius: 5px;
      padding: 5px 10px;
      transition: 0.4s all ease-out;
      line-height: 22px;
      svg{
        margin: -3px 0 0 2px;
      }
    }
    a.downloadBtn{
      border: 1px solid var(--sttl-font-secondary);
      color: var(--sttl-font-secondary);
      svg{
        margin: -3px 0 0 5px;
      }
      &:hover{
        background: var(--sttl-font-secondary);
        color: #fff;
      }
    }
  }
}

.app-rdss-cb-tr input[type="file"]{
  height:50px !important;
  line-height: 3;
}

.app-rdss-cb-tr input[type="file"]::-webkit-file-upload-button{
  height:50px !important;
}

.app-rdss-cb-tc .financialForm .financialMainFrom .tab-content .tcList.RevenueDetailsTable .table > :not(caption) > * > * {
  border-right: 1px solid #ccc;
}