@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");
@import "./_mixin.scss";
@import "./_var.scss";
@import "./highcontrast.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

a {
  @include transition();
}

body {
  overflow-x: hidden;

  .text-warning {
    color: var(--sttl-font-primary) !important;
  }
}

section.bannerSection.schemeSection {
  padding: 30px 0 25px;
}

.bannerSection {
  // background-image: url(../images/rec-banner.jpg);
  background: linear-gradient(179deg, #2541aa, #8c7295);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 1s ease infinite;
  -moz-animation: AnimationName 1s ease infinite;
  animation: AnimationName 1s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 49% 0%;
    }

    50% {
      background-position: 52% 100%;
    }

    100% {
      background-position: 49% 0%;
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 49% 0%;
    }

    50% {
      background-position: 52% 100%;
    }

    100% {
      background-position: 49% 0%;
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 49% 0%;
    }

    50% {
      background-position: 52% 100%;
    }

    100% {
      background-position: 49% 0%;
    }
  }

  width: 100%;
  // height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h1 {
    // padding-top: 55px;
    color: var(--sttl-font-white);
    font-weight: 200;
    font-size: 38px;
    letter-spacing: 0.52px;
    line-height: 50px;
  }

  .bannerModiWrap {
    // position: var(--sttl-position-absolute);
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .imgWrap {
    position: var(--sttl-position-absolute);
    z-index: 9;
    bottom: -40px;
    left: 95px;
    width: 163px;
  }

  span {
    font-weight: 900;
    text-shadow: 0px 4px 10px #000;
  }

  .quote {
    position: relative;
    background: var(--sttl-font-white);
    // margin: 0 13px;
    left: 0;
    border-radius: 10px;
    padding: 18px 251px 18px 80px;
    height: 125px;
    bottom: -40px;
    overflow: hidden;
    box-shadow: 0 0 60px #bcbcbc52;

    .imgWrap {
      position: var(--sttl-position-absolute);
      top: -66px;
    }

    .quoteInner {
      padding-left: 224px;

      p {
        font-size: var(--sttl-font-size-18);
        font-weight: var(--sttl-font-medium);
        letter-spacing: 0.3px;

        svg {
          color: rgba(29, 67, 130, 50%);
          width: 30px;
          height: 30px;
        }
      }

      p.pmName {
        font-size: var(--sttl-font-size-16);
        color: var(--sttl-font-primary);
      }

      p.pmTag {
        color: #676767;
        font-size: var(--sttl-font-size-14);
        font-weight: normal;
      }

      .quotation {
        position: relative;
        line-height: 24px;
        // &::before {
        //     content: '';
        //     background-image: url(../images/quotation-mark.svg);
        //     width: 31px;
        //     height: 24px;
        //     position: var(--sttl-position-absolute);
        //     top: 0;
        //     left: -40px;
        // }

        // &::after {
        //     content: '';
        //     background-image: url(../images/quotation-mark.svg);
        //     width: 31px;
        //     height: 24px;
        //     position: var(--sttl-position-absolute);
        //     bottom: 0;
        //     right: 15px;
        //     transform: rotate(180deg);
        // }
      }
    }

    &::before {
      content: "";
      background-image: url(../images//quotation-mark-big.svg);
      width: 100%;
      width: 188px;
      position: var(--sttl-position-absolute);
      height: 188px;
      background-repeat: no-repeat;
      right: 50px;
      top: 0;
      background-size: 100%;
    }
  }
}

.reportSection {
  padding: 50px 0;

  a {
    text-decoration: none;
    color: var(--sttl-font-black);

    &:hover {
      p {
        @include transition();
        color: var(--sttl-font-primary);
      }
    }
  }

  .pressSwiper {
    max-height: 375px;
  }

  .pressRelease {
    display: flex;
    align-items: center;
    padding: 20px 0 30px;

    // border-bottom: 1px solid #aaa;
    span {
      color: var(--sttl-font-primary);

      svg {
        margin-right: 10px;
        margin-top: -4px;
      }
    }

    .pdfDetails p {
      font-weight: var(--sttl-font-medium);
      margin-bottom: 0;
      line-height: 1.4;
      margin-top: 5px;

      span {
        color: var(--sttl-font-black);
        font-weight: 300;
      }
    }

    .pdfIcon svg {
      color: var(--sttl-font-primary);
      width: 17px;
      height: 23px;
    }
  }

  .viewAll {
    a {
      text-decoration: none;
      margin-right: 20px;
      color: var(--sttl-font-primary);
    }
  }

  .mediaNav {
    width: 60px;
    display: flex;
    justify-content: space-between;

    .prev,
    .next {
      background-image: url(../images/arrow-right.svg);
      width: 20px;
      height: 15px;
      cursor: pointer;
    }

    .prev.swiper-button-disabled,
    .next.swiper-button-disabled {
      background-image: url(../images/arrow-gray-right.svg);
    }

    .prev {
      transform: rotate(180deg);
    }
  }

  .mediaSwiper {
    padding-top: 20px;
  }
}

.aboutAccordioin {
  margin-top: 20px;
  display: flex;
  margin-bottom: 50px;

  .TabButtons {
    flex: 0 0 auto;
    width: 100%;
    max-width: 25%;
    padding-right: 25px;

    ul {
      background: var(--sttl-font-white);
      border-radius: 10px;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.11);
      padding: 20px 0px;

      li {
        a {
          padding: 15px 30px;
          color: rgba(100, 100, 100, 1);
          font-size: var(--sttl-font-size-18);
          display: block;

          &[aria-selected="true"] {
            background-color: var(--sttl-font-primary);
            color: #fff;
            font-weight: var(--sttl-font-medium);
          }
        }
      }
    }
  }

  .Accordion-group {
    flex: 0 0 auto;
    width: 100%;
    max-width: 75%;

    .Tab-panel {
      .ActionCenterSection {
        background-image: url(../images/about-tab.jpg);
        overflow: hidden;
        border-radius: 10px;
      }

      .ActionCenterSectionEA {
        .col-lg-25.col-6.d-flex.align-items-center.justify-content-center {
          flex: 0 0 auto;
          width: 20%;
        }

        .tabPanelInner {
          padding: 75px 10px;
        }
      }

      .tabPanelInner {
        padding: 75px 0;
        width: 100%;
        text-align: center;
        height: 100%;
        border: 1px solid rgba(156, 156, 156, 0.15%);
        transition: 0.3s all ease-out;
        position: relative;
        overflow: hidden;

        .hoverContent {
          transition: 0.3s all ease-out;
          padding: 0px 10px;
          text-align: center;
          position: absolute;
          height: 0;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f58216;
          visibility: hidden;
          flex-wrap: wrap;
        }

        .hoverContent p {
          font-size: var(--sttl-font-size-14);
        }

        svg {
          color: var(--sttl-font-white);
          margin-bottom: 20px;
          transition: 0.3s all ease-out;
        }

        h5 {
          color: var(--sttl-font-white);
        }

        &:hover {
          // background-color: var(--sttl-font-primary);
          .hoverContent {
            opacity: 1;
            visibility: visible;
            color: var(--sttl-font-white);
            height: 100%;
          }
        }
      }
    }

    .Tab-panel,
    .Accordion-panel {
      .SchemeOverviewSection {
        .heading {
          h2 {
            @include customFont(34px);
            color: var(--sttl-font-secondary);
            font-weight: var(--sttl-font-bold);

            span {
              color: var(--sttl-font-primary);
            }
          }

          p {
            color: #707070;
            @include customFont(24px);
          }
        }

        .imgBox {
          margin-bottom: 40px;

          p {
            color: #4d4d4d;
            font-size: var(--sttl-font-size-18);
            line-height: var(--sttl-font-size-30);
            font-weight: var(--sttl-font-light);
            padding-right: 40px;
          }

          img {
            border-radius: 16px;
          }
        }

        .schemeFeature {
          background-color: #f6f6f6;
          border-radius: 16px;
          padding: 40px 70px 40px 32px;
          margin-bottom: 40px;

          h3 {
            font-size: var(--sttl-font-size-26);
            color: var(--sttl-font-secondary);
            line-height: 39px;
          }

          p {
            color: var(--sttl-font-black);
            font-size: var(--sttl-font-size-20);
          }

          h6 {
            font-size: var(--sttl-font-size-20);
            line-height: 30px;
          }

          ul {
            li {
              margin-bottom: 0;
            }
          }
        }

        .contentSec {
          h4 {
            font-size: var(--sttl-font-size-20);
            font-weight: var(--sttl-font-medium);
          }

          .part {
            padding-left: 20px;
            margin-bottom: 25px;

            h5 {
              font-size: var(--sttl-font-size-18);
              font-weight: var(--sttl-font-bold);
              color: var(--sttl-font-primary);
            }

            p {
              font-size: var(--sttl-font-size-18);
              font-weight: var(--sttl-font-light);
            }

            &.partA {
              padding: 0 90px 0 0;
              border-right: 1px solid rgba(112, 112, 112, 0.3);
            }
          }
        }

        ul {
          padding-left: 5px;
          margin-bottom: 0;
          list-style-type: none;

          li {
            font-size: var(--sttl-font-size-18);
            font-weight: var(--sttl-font-light);
            position: relative;
            padding-left: 20px;
            color: #4d4d4d;
            margin-bottom: 15px;

            &::before {
              content: "\2022";
              color: var(--sttl-font-primary);
              font-weight: var(--sttl-font-bold);
              display: inline-block;
              position: absolute;
              left: 0;
              font-size: 20px;
              line-height: 0;
              top: 13px;
            }

            ul {
              margin-top: 15px;

              li {
                margin-bottom: 5px;

                &::before {
                  content: "-";
                  font-weight: var(--sttl-font-light);
                  color: #4d4d4d;
                }
              }
            }
          }
        }
      }
    }
  }
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  img {
    width: 50%;
  }

  .backBtn {
    background: var(--sttl-font-secondary);
    color: var(--sttl-font-white);
    padding: 10px 25px;
    border-radius: 100px;
    margin: 0 auto;
    font-size: var(--sttl-font-size-14);

    &:hover {
      color: var(--sttl-font-secondary);
      background: transparent;
      border: 1px solid var(--sttl-font-secondary);
    }
  }
}

.loginWrap {
  height: calc(100vh - 223px);

  .login-main {
    background-image: url(../images/login-bg.jpg);
    padding: 0;
    background-position: center;
    background-size: cover;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .loginPosition {
      height: calc(100vh - 231px);
    }

    .loginbox {
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(255, 255, 255, 0.19);
      backdrop-filter: blur(9px);
      margin: 0px auto;
      border-radius: 9px;
      padding: 20px;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);

      label {
        color: #fff !important;
      }

      h2 {
        margin-bottom: 20px;
      }

      small {
        font-size: var(--sttl-font-size-12) !important;
        background: var(--sttl-font-red);
        color: var(--sttl-font-white) !important;
        width: 70px !important;
        padding: 3px 7px;
        top: 28px !important;
      }

      small:before {
        font-size: 0px !important;
      }

      input {
        height: 44px;
        border-radius: 0;
      }

      .form-field {
        margin-bottom: 20px;
        position: relative;

        .form-control.pass-show {
          border: 0;
          color: var(--sttl-font-black);
          background-color: transparent;

          &:hover {
            color: var(--sttl-font-black);
            background-color: transparent;
          }

          &:active {
            border: 0;
          }

          &:focus {
            border: 0;
            box-shadow: none;
          }

          svg {
            font-size: 24px;
          }
        }
      }

      .formBtnSection {
        padding-top: 10px;
      }

      .btnSubmit {
        background-color: #ffb433;
        color: var(--sttl-font-black);
        margin: 0;
      }
    }

    .control-label {
      color: var(--sttl-font-black);
      font-size: var(--sttl-font-size-14);
      margin-bottom: 5px;
    }
  }
}

.loginFooter {
  box-shadow: none !important;
}

.screenReader {
  margin: 50px 0;

  // min-height: 390px;
  p {
    font-size: var(--sttl-font-size-18);
  }

  h2 {
    font-weight: var(--sttl-font-bolder);
    margin: 30px 0 40px;

    &.accordion-header {
      margin: 0;
    }
  }

  h4 {
    font-weight: var(--sttl-font-bolder);
  }

  .table-responsive {
    box-shadow: 0 3px 15px rgba(207, 207, 207, 1);
    display: inline-block;
    overflow-y: hidden;

    table {
      width: auto;
      min-width: 500px;
      margin-bottom: 0;

      thead {
        background: var(--sttl-font-secondary);
        color: #fff;

        th {
          text-align: left !important;
          padding: 15px !important;
          font-weight: normal;
        }
      }

      tbody {
        td {
          padding: 15px;

          a {
            color: var(--sttl-font-primary);
            text-decoration: none;

            svg {
              color: var(--sttl-font-secondary);
            }
          }
        }
      }
    }
  }

  .accordion {
    .accordion-button:not(.collapsed) {
      color: var(--sttl-font-secondary);
      background-color: #a6bee136;
      box-shadow: none;
    }
  }

  .caption {
    @include blankPara;
  }

  .ActionCenterSection {
    background-size: cover;
  }

  ul {
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 5px;

      &::before {
        content: "";
        background-image: url(../images/check.svg);
        background-size: 18px;
        position: absolute;
        left: 0;
        width: 18px;
        height: 18px;
        top: 4px;
      }
    }
  }
}

.AboutSection {
  h5,
  h4 {
    font-size: var(--sttl-font-size-23);
    color: var(--sttl-font-secondary);
    font-weight: var(--sttl-font-bold);
  }

  h4 {
    font-weight: var(--sttl-font-bolder);
  }

  h6 {
    font-size: var(--sttl-font-size-20);
    color: var(--sttl-font-secondary);
    font-weight: var(--sttl-font-bold);
  }

  ul {
    list-style-type: none;
    padding-left: 5px;
    margin-bottom: 50px;

    li {
      font-size: var(--sttl-font-size-18);
      font-weight: var(--sttl-font-light);
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px;
      color: #4d4d4d;

      b {
        font-weight: var(--sttl-font-bold);
      }

      &::before {
        content: "\2022";
        color: var(--sttl-font-primary);
        font-weight: var(--sttl-font-bold);
        display: inline-block;
        position: absolute;
        left: 0;
        font-size: var(--sttl-font-size-23);
        line-height: 0;
        top: 13px;
      }
    }
  }

  .contactDiv {
    background-color: #f6f6f6;
    border-radius: 16px;
    padding: 40px 70px 40px 32px;
    margin-bottom: 20px;
  }

  .mainMap {
    position: relative;
    padding-bottom: 20%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 50px;
    border-radius: 16px;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.form-control.green {
  color: var(--sttl-font-green) !important;
  font-weight: 500;
}

.form-control.red {
  color: var(--sttl-font-red) !important;
  font-weight: 500;
}

.form-group.has-error small,
.child-has-error.has-error > small {
  color: var(--sttl-font-red);
}

// .view-field {
//   background: #f7f7f7;
//   padding: 10px 15px;
//   border: 1px solid #ddd;
//   border-radius: 6px;
//   min-height: 40px;
//   min-width: 100px;
//   font-size: 12px;
// }

.view-field.green {
  color: var(--sttl-font-green) !important;
  font-weight: 500;
}

.view-field.red {
  color: var(--sttl-font-red) !important;
  font-weight: 500;
}

// Form Validation
.form-group.has-error,
.child-has-error.has-error {
  position: relative;
}

.form-group.has-error small,
.child-has-error.has-error > small {
  position: absolute;
  top: 0;
  width: 16px;
  height: 25px;
  font-size: 0px;
  right: 0;
}

.form-group.has-error small:before,
.child-has-error.has-error > small:before {
  content: "x";
  font-size: var(--sttl-font-size-12);
  background: var(--sttl-font-red);
  display: block;
  color: var(--sttl-font-white);
  text-align: center;
  border-radius: 0px 0px 0px 6px;
}

.tableRadio .d-flex.align-items-center {
  justify-content: center;
}

.col-md-hidden {
  height: 0px;
}

.file.has-error small::before {
  content: "";
}

.file.has-error small {
  font-size: 11px;
  position: inherit;
  width: 100%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: table !important;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: 0px auto;
}

.spinner-outer {
  width: 50px;
  display: table-cell;
  vertical-align: middle;
}

.nice-dates {
  &:after {
    content: "";
    position: absolute;
    width: 12px;
    background-position: 50% 50%;
    background-size: 12px;
    top: 50%;
    right: 10px;
    height: 12px;
    transform: translateY(-50%);
  }

  input.form-control {
    background-position: 10px 50%;
    padding-left: 35px;
    background-size: 15px;
    position: relative;
    z-index: 1;
  }

  .nice-dates-popover {
    padding: 5px;
    box-shadow: 0 1px 8px #aaa;
    max-width: 270px;
  }
}

.nice-dates {
  a.nice-dates-navigation_previous:before {
    border-right: 2px solid #848484;
    border-top: 2px solid #848484;
    width: 10px;
    height: 10px;
  }

  .nice-dates-navigation_current {
    font-size: var(--sttl-font-size-14);
    font-weight: 500;
  }

  a.nice-dates-navigation_next:before {
    border-right: 2px solid #848484;
    border-top: 2px solid #848484;
    width: 10px;
    height: 10px;
  }

  .nice-dates-day {
    height: 38px;
    font-size: var(--sttl-font-size-14);

    span.nice-dates-day_month {
      display: none;
    }

    &:before {
      background: var(--sttl-font-primary);
    }

    &:after {
      border: 2px solid var(--sttl-font-primary);
    }
  }

  .nice-dates-day.-today {
    span {
      font-weight: 600;
      border-radius: 100%;
      color: var(--sttl-font-primary) !important;
    }
  }

  input {
    font-size: var(--sttl-font-size-14);
    color: #323232;
  }
}

// Site Map
.sitemap {
  padding: 0 0 0 30px;
  margin: 0;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    width: 3px;
    background-color: var(--sttl-font-secondary);
    display: block;
    left: 0;
  }

  &::after {
    content: "";
    height: 11px;
    left: -4px;
    position: absolute;
    bottom: 0;
    width: 11px;
    background: var(--sttl-font-secondary);
    border-radius: 50%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 10px;

      a.menuParent {
        color: var(--sttl-font-white);
        font-size: 1.063em;
        line-height: 25px;
        text-decoration: none;
        padding: 7px 20px;
        transition: 500ms all;
        background: var(--sttl-font-secondary);
        border: 1px solid var(--sttl-font-secondary);
        display: inline-block;
        margin-left: 0;
        position: relative;

        &::before {
          position: absolute;
          left: -30px;
          top: 50%;
          margin-top: -1px;
          content: "";
          background-color: var(--sttl-font-secondary);
          width: 29px;
          height: 2px;
        }

        &:hover {
          background-color: var(--sttl-font-primary);
          color: var(--sttl-font-white);
          border-color: var(--sttl-font-primary);
          padding-left: 25px;
        }
      }

      a {
        transition: 500ms all;
        color: var(--sttl-font-secondary);
        background: var(--sttl-font-white);
        padding: 5px 10px;
        margin-left: 30px;
        font-size: 1em;
        line-height: 25px;
        text-decoration: none;
        border: 1px solid var(--sttl-font-secondary);
        display: inline-block;
        position: relative;

        &:hover {
          padding-left: 15px;
          background-color: var(--sttl-font-secondary);
          border-color: var(--sttl-font-secondary);
          color: var(--sttl-font-white);
        }
      }
    }
  }
}

#canv {
  float: left;
  margin-bottom: 20px;
}

#reload_href {
  margin-left: 15px;
  display: inline-block;
  margin-top: 5px;
}

.pass-show {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 40px !important;
}

.swal-button--confirm {
  background-color: #dd6b55;
}

.accordion-button:focus {
  border: 0;
  box-shadow: none;
}

.is-sticky {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  padding: 15px 0;
  -webkit-animation: headerSlideDown 0.8s ease forwards;
  animation: headerSlideDown 0.8s ease forwards;
  max-width: 1392px;
  margin: 0 auto;
  padding: 10px 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.form-group.has-success small {
  color: var(--sttl-font-green);
  font-size: 11px;
  font-weight: bold;
}

.StepButtonContent-d7-0-2-27 {
  font-size: 1rem;
  font-variant-numeric: tabular-nums;
}

.ref-nav-item {
  width: 20% !important;
}

.ref-nav-item button {
  font-size: 15px !important;
}

.table.ref-score-table {
  width: 70%;
  margin: 0px auto;
}

.table.ref-score-table thead tr th {
  border: 1px solid #ddd;
  padding: 10px !important;
}

.table.ref-score-table tbody tr td {
  border: 1px solid #ddd;
  padding: 10px !important;
}

.ref-total {
  background: #1b2f68 !important;
  color: #fff;
}

.misReportSelect {
  font-size: 14px;
  z-index: 99;
}

.field-comp-multiselect {
  font-size: 14px !important;
  z-index: 99;
}

.excelButton {
  background: transparent !important;

  svg {
    color: #00c056;
    font-size: var(--sttl-font-size-30);
  }
}

.bsSearchBtn {
  background-color: #00c056;
  border: 1px solid #fff;
  padding: 7px 12px;
  margin-top: 31px;

  svg {
    color: #fff;
  }

  &:hover {
    background-color: #fff;
    border: 1px solid #00c056;

    svg {
      color: #00c056;
    }
  }
}

.mapbg {
  background: linear-gradient(
    90deg,
    rgba(0, 86, 184, 1) 0%,
    rgba(188, 215, 247, 1) 100%
  );
  padding: 0px 15px;
  color: #fff;
  font-size: 12px;
}

.MpState {
  overflow: hidden !important;
}

.mapbg .text-end {
  color: #000;
}

.with-tooltip {
  button.btn {
    color: #ffffff;
  }
}

.twitter-timeline {
  position: relative;
  padding-bottom: 100%;
  /* 16:9 */
  height: 0;
}

.twitter-timeline iframe {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.misreportTable table thead tr th {
  vertical-align: top !important;
}

.pe-cursor {
  cursor: pointer;
}

// height - 25vh, 50vh, 75vh, 100vh
@for $i from 1 through 4 {
  .h-#{($i * 25)}vh {
    height: $i * 25vh !important;
  }
}

.content-spinner {
  &.spinner-container {
    position: relative;
    background: transparent;

    .loading-spinner {
      border-top-color: var(--sttl-font-secondary);
    }
  }
}

.is-invalid {
  border-color: #ff0000 !important;
  color: #ff0000 !important;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #000;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
  }

  33% {
    box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
  }

  66% {
    box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
  }

  100% {
    box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
  }
}

body
  .financialForm
  .financialMainFrom
  .tab-content
  .RevenueDetailsTable.PqTable {
  .table.caption-top.newTableUcss {
    thead {
      th {
        font-size: var(--sttl-font-size-16) !important;
        font-weight: 600 !important;
      }

      th.border-end {
        border-right: 10px solid #fff !important;
      }

      th.fw-normal {
        font-weight: 400 !important;
      }
    }
  }
}

body
  .financialForm
  .financialMainFrom
  .table-responsive
  table.table.caption-top.newTableUcss
  thead.text-center {
  position: sticky;
  top: -10px;
  z-index: 1;
  background-color: #fff;

  th {
    position: relative !important;
  }

  th.noPadding {
    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #dee2e6;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      height: 100%;
      border-right: 10px solid #fff;
    }
  }

  // th.noPadding {
  //   padding: 0!important;
  //   span {
  //     padding: 10px 6px;
  //     display: block;
  //     border-bottom: 1px solid #000;
  //   }
  // }
}

p.text-white.px-4.display-6 {
  font-size: 14px;
}

.bgSizeCover {
  background-size: cover;
}

.uploadUcss {
  .form-group.file .fileUpload.position-relative.text-center.align-items-end {
    .downEqule {
      display: flex;
      width: 100%;
    }

    .customUpload {
      width: 220px;
      height: 38px;
    }

    select.form-control.text-start.form-select.form-control {
      width: 50%;
      height: 38px;
    }

    .viewupload {
      border-radius: 5px;
      height: 38px;
      margin-left: 20px;
      max-width: calc(30% - 20px);
    }
  }
}

@for $i from 1 through 4 {
  $value: $i * 25;

  .w-#{$i} {
    width: $value + px !important;
  }
}

.bs-w-modal-width {
  max-width: var(--bs-modal-width) !important;
}

.fz-16 {
  font-size: 16px !important;
}

.misSelectReportPosition {
  position: relative;
  z-index: 999;
}

.other_documents .fileUpload .customUpload {
  position: static;
}

// section.InnerBanner + section {
//   min-height: calc(100vh - 346px);
// }

.css-hlgwow input {
  height: auto !important;
}

.tabQualityMonitoring {
  height: 100%;
  padding: 20px;
  text-align: center;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabQualityMonitoring h5 {
  font-size: 16px;
  font-weight: 600;
}

.ligt-bg {
  background: #ecf1f9;
  color: #1b2f68;
  padding: 15px 5px 15px 20px;
  font-weight: 500;
}

//Document Center
.dms-search-box {
  width: 270px;
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  // margin: auto;

  .dms-search-input {
    max-width: 100% !important;
    width: 100% !important;
    font-size: var(--sttl-font-size-16);
    padding: 6px 16px;
    background-color: #eaeaeb;
    // color: #6c6c6c;
    border-radius: 50px;
    border: none;
    transition: all 0.4s;

    &::placeholder {
      color: #6c6c6c;
    }

    &:-ms-input-placeholder {
      color: #6c6c6c;
    }

    &::-ms-input-placeholder {
      color: #6c6c6c;
    }
  }

  .dms-search-btn {
    background-color: transparent;
    font-size: var(--sttl-font-size-18);
    padding: 6px 9px;
    margin-left: -45px;
    border: none;
    color: #6c6c6c;
    transition: all 0.4s;
  }
}

.financialMainFrom .tab-content .notification-table .table tbody tr td {
  padding: 0px !important;
}

.financialMainFrom .tab-content .notification-table .table tbody tr td a {
  text-decoration: none;
  color: #000;
  padding: 10px 0px;
  display: block;
}

.mistitle a {
  color: #f90;
  text-decoration: none;
}

.mistitle a:hover {
  color: #000;
}

.fd-tab-top .fYearMonth.setPosition {
  width: 45% !important;
}

.fd-tab-top .radioTitleSection .radioYear .form-check .form-check-label {
  font-size: 13px !important;
}

.uploadSelect.disbDocument .form-group.file .form-field {
  display: block;
}

.fixed-table-col {
  position: sticky;
  left: 0;
  z-index: 4;
}

th.fixed-table-col {
  z-index: 10 !important;
  position: sticky !important;
}

.updated-info {
  font-size: 14px;
  color: #f90;
  text-align: right;
  margin-top: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.updated-info p {
  margin: 0px;
}

.updated-info span {
  color: #000;
}

.field-comp-select-search {
  [class$="-ValueContainer"] {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  [class$="-singleValue"] {
    font-size: 12px;
  }

  [class$="-placeholder"] {
    font-size: 14px;
  }

  [class$="-Input"] {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
  }

  [class$="-indicatorSeparator"] {
    padding: 0px !important;
  }
}

.progressive-details label {
  font-size: 14px !important;
}
.pdYearMonth {
  width: auto !important;
}

.search-hover .bsSearchBtn:hover {
  background-color: #00c056;
  border: 1px solid #00c056;
}
.search-hover .dIcnBtn a svg {
  width: 35px;
  height: 35px;
}
// swal Popup cancel button
.swal-button-container .swal-button.swal-button--cancel {
  color: #ffffff;
}
.swal-button-container .swal-button.swal-button--cancel:hover {
  color: #1b2f68;
}

.fundinlinecss .other_documents .fileUpload .customUpload {
  position: static;
  width: 99%;
}
.fundinlinecss.other-document-popup .modal-body .fileUpload .customUpload {
  position: absolute;
}

// AGSDP
.gsdpinlinecss .form-group.textarea .view-field {
  height: auto !important;
  min-width: 100px;
  line-height: 20px !important;
}
.gsdpinlinecss .form-group.ynradio .view-field {
  width: 100px !important;
  text-align: center !important;
  min-height: auto;
}
.gsdpinlinecss .form-group.date .view-field {
  width: 100px !important;
  text-align: center !important;
  min-height: auto;
}
.gsdpinlinecss .form-group.amount .view-field {
  width: 100px !important;
  text-align: center !important;
  min-height: auto;
}
.gsdpinlinecss .form-group.file .form-floating .fileUpload {
  width: auto !important;
}
.gsdpinlinecss
  .form-group.file
  .form-floating
  .fileUpload
  .form-control.form-select {
  width: 50px;
}
.gsdpinlinecss .form-group.file .form-floating {
  display: flex;
  justify-content: center;
}
.gsdpinlinecss .form-group.file .view-form-field {
  width: 100% !important;
}
.gsdpinlinecss .form-group.file .form-floating a.viewupload {
  width: 30px;
  background-color: #c9e4c9;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  border-radius: 5px !important;
  margin-left: 4px;
  display: block;
}
.gsdpinlinecss .form-check {
  display: flex;
  align-items: center;
}
.gsdpinlinecss label.form-check-label {
  margin-left: 5px;
  margin-top: 5px;
}
.gsdpinlinecss .form-label::after {
  display: none;
}

body
  .financialForm
  .financialMainFrom
  .table-responsive
  table.lossIOT.lrMultipleID
  thead {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;
  tr {
    th {
      position: unset;
    }
  }
}

.capture-photo {
  .preview-photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video-stream > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.capture-photo-preview {
  .remove-photo {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }
  .is-remove:hover {
    .remove-photo {
      display: flex;
    }
  }
}

.blink-note {
  animation: blinkerNote 2.5s linear infinite;
}

@keyframes blinkerNote {
  50% {
    opacity: 0;
  }
}

nav{
  ul.pagination {
    li.page-item{      
      margin: 0 5px;
      &.col-1 {
        width: 70px;
        input {
          border: 1px solid #1b2f68;
          padding: 7px;
        }  
      } 
      button.page-link {
        border-radius: 5px;
        border: 1px solid #1b2f68;
        margin: 0;
        color:#1b2f68;
        &:hover{         
          color: #fff;
          background-color:#1b2f68;
        }
        &:disabled {
          border-color: #dfdfdf;
          background-color: #dfdfdf;
          color:#aaa;
        }
        &:focus-visible, &:focus{
          box-shadow: none;
          outline: none;
          background-color: #fff;
        }
      }
      &:last-child, &:first-child{
        button{
          background-color: #1b2f68;
          color:#fff;
          &:hover{         
            background-color: #fff;
            color:#1b2f68;
          }      
          &:disabled {
            border-color: #dfdfdf;
            background-color: #dfdfdf;
            color:#aaa;
          }  
        }
      }  
    }
  }
} 
nav + .page-item{
  margin-top: -48px;
  text-align: right !important;
  padding-bottom: 25px;
}