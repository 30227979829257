 
        .bannerVideoSection {
          background: none;
          background-color: #000;
        }
        .bannerVideoSection .swiper-pagination-bullet {
          background-color: #fff;
        }
        .bannerVideoSection .schemeSection {
          position: relative;
        }
        .banner-video-wrap {
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         z-index: 0;
         overflow: hidden;

        }
        .banner-video {
         position: relative;
         left: 0;
         top: -52%;
         width: 100%;
         height: 160%;
         object-fit: cover;
        }
        section.bannerSection.schemeSection.bannerVideoSection {
           display: flex;
           flex-direction: column;
           justify-content: flex-end;
           padding-bottom: 0;
           margin-bottom: 200px;
          //  height: calc(100vh - 112px - 200px - 10px);
           max-height: 520px;
           position: relative;
        }
        section.bannerSection.schemeSection.bannerVideoSection:before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
         background-repeat: no-repeat;
         background-size: cover;
         opacity: 0.83;
         width: 100%;
         height: 100%;
         max-height: 292px;
         z-index: 1;
        }

        section.bannerSection.schemeSection.bannerVideoSection .schemeSection {
           position: relative;
           bottom: -170px;
           max-width: 1010px;
           margin: 0 auto;
           z-index: 2;
           width: 100%;
        }

        .schemeSection.schemeBoxType2 .schemeInner {
         flex-direction: column;
         border-radius: 0;
         box-shadow: none;
         position: relative;
         padding-top: 100%;
         border: 5px solid #fff;
        }
        .schemeSection.schemeBoxType2 .schemeInner:before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
         background-repeat: no-repeat;
         background-size: cover;
         opacity: 0.83;
         width: 100%;
         height: 100%;
         max-height: 192px;
         z-index: 1;
        }
        .schemeSection.schemeBoxType2 .schemeInner img {
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         object-fit: cover;
         z-index: 0;
         max-width: 100%;
        }
        .schemeSection.schemeBoxType2 .schemeInner h5 {
         font-size: 18px;
         line-height: 1.22;
         font-weight: 600;
         position: absolute;
         left: 0;
         bottom: 0;
         padding: 20px 15px;
         color: #fff;
         text-align: center;
         background: transparent;
         max-width: 100%;
         justify-content: center;
         z-index: 2;
        }
        .bannerVideoSection .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
        .bannerVideoSection .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
         transform: scale(1);
         background-color: #D4D4D4;
         opacity: 1;
        }
        .bannerVideoSection .schemeSection .swiper .swiper-pagination .swiper-pagination-bullet {
          width: 15px;
          height: 15px;
          background: #D4D4D4;
          opacity: 1;
        }
        .bannerVideoSection .schemeSection .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: var(--sttl-font-primary)
        }

        @media (max-width: 1499px) {
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection{ bottom: -200px; }
          section.bannerSection.schemeSection.bannerVideoSection{ margin-bottom: 230px; }
        }
        @media (max-width: 1366px) {
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection{max-width: 850px;bottom: -180px;}
          section.bannerSection.schemeSection.bannerVideoSection{ margin-bottom: 200px;}
        }
        @media (max-width: 1024px) {
          .schemeSection.schemeBoxType2 .schemeInner img{height: 220px}
          section.bannerSection.schemeSection.bannerVideoSection{max-height: 340px;}
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection{bottom: -150px;}
        }
        @media (max-width:768px) {
          .schemeSection.schemeBoxType2 .schemeInner h5{font-size: 14px;}
          section.bannerSection.schemeSection.bannerVideoSection {max-height: 265px;}
        }
        @media  (max-width:649px) {
          .schemeSection.schemeBoxType2 .schemeInner{padding-top: 75%;}
          section.bannerSection.schemeSection.bannerVideoSection {max-height: 210px;}
          section.bannerSection.schemeSection.bannerVideoSection {margin-bottom: 130px;}
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection{max-width: 450px;}
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection {bottom: -110px;}
        }
        @media  (max-width:575px) {
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection {bottom: -150px;}
        }
        @media  (max-width:499px) {
          section.bannerSection.schemeSection.bannerVideoSection {max-height: 150px;}
          section.bannerSection.schemeSection.bannerVideoSection .schemeSection{max-width: 230px;}
        }