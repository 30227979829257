.eaWrap{
    margin-top: 20px;
    .eaHeading{
        background: #A6BEE136;
        padding: 10px 0;
        border: 1px solid #CED3DF;
        margin-top: 20px;
        text-align: center;
        h4{
            font-size: var(--sttl-font-size-20);
            margin-bottom: 0;
        }
    }
    .eaTable{
        .table{
            tr{
                border-bottom: 1px solid #ccc;
                td{
                    padding: 15px 5px !important;
                    border-bottom: 0;
                }
            }
        }
    }
}