.app-agsdp-inputForm{
    .accordion{
        .table-responsive.RevenueDetailsTable{
            max-height: 100%!important;
            a.btn.btn-default.border.border-dark.me-2{
                font-size: 14px;
                color: #1b2f68;
                border-color: #1b2f68!important;
                transition: 0.3s all ease-in-out;
                &:hover{
                    background-color: #1b2f68;
                    color: #fff!important;
                }
            }
        }
    }
    .financialForm .financialMainFrom .tab-content .RevenueDetailsTable tr td .fileUpload .dowupEqule{
        max-width: 100%;width: auto;
    }
    .table-responsive.RevenueDetailsTable.formFilledSection .form-group.file .form-field a.viewupload {
        width: 35px;
    }
    .activeRow td .form-group.file .form-field .fileUpload, .app-agsdp .activeRow td .form-group.file .form-field .downEqule {
        justify-content: start;
    }
}
.app-agsdp {
    tr.heading {
        background-color: #cceeee;

        th,
        td {
            padding: 20px 10px !important;
            color: #000000 !important;
        }
    }
   
    .activeRow.row {
        padding: 0 20px;
        font-size: 14px;
        color: #212f66;
        font-weight: 500;
        line-height: 18px;
        align-items: center;
        margin-bottom: 10px;                    
        .activeRow.row {
            padding: 0 0px 0 24px;
        }
        table{
            margin:12px 0;
        }
        td{
            padding-top: 14px !important;
            padding-bottom: 0 !important;
        }
    }
    .activeRow{
        &:first-child{
            .accordion-item{
                margin-top: 20px;
            }
        }
        .accordion-item{
            padding: 10px 0!important;
            h2.accordion-header{
                button{
                    padding: 0;
                    background-color: #edf1f9;
                    &::after{
                        position: absolute;
                        right: 15px;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                    &:focus-visible{
                        outline: none;
                    }            
                }
                .activeRow.heading {
                    background: #edf1f9;
                    font-weight: 600;
                    padding: 10px 20px;
                    color: #212f66;
                }
            }
        }
        td{
            .form-group.file{
                .form-field{
                    .fileUpload, .downEqule{
                        width: 175px;
                        select{
                            width: 90px;
                        }
                    }
                }
            }
        }
        .innerTable{
            padding: 0 30px;
            background: #edf1f966;
            margin: 20px 50px;
            border-radius: 10px;
        }
    }
}

.app-agsdp .activeRow.row {
    table.table.totalTable{
        margin-top:0;
        tr{
            td{
                padding-top: 0!important;
                &:first-child{
                    min-width: 155px;
                    max-width: 100px;
                    width: 100px!important;
                }
                &:nth-child(2){
                    min-width: 100px;
                    max-width: 100px;
                    width: 100px!important;
                }
                &:nth-child(3){
                    min-width: 115px;
                    max-width: 100px;
                    width: 100px!important;
                    .col-md-0 {
                        padding: 0px 11px 10px;
                    }
                }
                .col-md-0{
                    padding-top:0;
                }
            }
        }
    }
    .innerTable.grpFiveTable{
        max-width:900px;
        margin: 20px auto;
        table{
            tr{
                td{
                    &:first-child, &:nth-child(5){
                        min-width: 50px;
                        width: 50px!important;
                    }
                    &:nth-child(2), &:nth-child(3), &:nth-child(4){
                        min-width: 200px;
                        width: 200px!important;
                    }
                }
            }
        }
        tr#grp5_total td {
            padding-top: 0!important;
            table {
                margin-top: 0;
            }
        }
    }
    .innerTable.grpEightTable{
        max-width:1200px;
        margin:20px auto;
        table{
            tr{
                th{
                    &:first-child{
                        width: 300px;
                        min-width: 300px;
                        text-align: left!important;
                    }
                    &:th:nth-child(2), &:th:nth-child(3), &:th:nth-child(4), &:th:nth-child(5){
                        width: 150px;
                        min-width: 150px;
                    }
                }
            }
        }
    }
}
.app-agsdp-inputForm .activeRow.row{
    .innerTable.perAccOne{
        max-width: 950px;
        margin: 20px auto;
        table{
            tr{
                td{
                    padding-bottom: 14px !important;
                }
                &:last-child{
                    border-bottom: 0!important;
                }
            }
        }
    }
    &.perAccFive{
        table{
            tr{
                td{
                    padding-bottom: 14px !important;
                }
                &:last-child{
                    border-bottom: 0!important;
                }
            }
        }
    }
    .innerTable.perAccTwo{
        max-width: 700px;
        max-width: 700px;
        margin: 20px auto;
        table{
            tr{
                &:nth-child(2){
                    th{
                        &:first-child, &:nth-child(2){min-width: 50px;max-width: 50px;width: 50px;}
                        &:nth-child(2){min-width: 250px;max-width: 250px;width: 250px;}
                        &:nth-child(3){min-width: 250px;max-width: 250px;width: 250px;}
                    }
                }
                td{
                    padding-bottom: 14px !important;
                }
            }
        }
    }
    .innerTable.perAccEight{
        max-width: 1000px;
        margin: 20px auto;
        table{
            tbody{
                tr{
                    &:first-child{
                        display: none;
                    }
                    td{
                        padding-bottom: 14px !important;
                    }
                }
            }
        }
    }
}

.table-responsive.RevenueDetailsTable.formFilledSection{
    .col-md-2 tr, .col-md-2 td, .displayGrid{
        display: grid;
    }
    .paddingTop{
        padding-top: 0!important;
    }
    .form-group.file .form-field {
        .view-form-field.text-center {
            width: 100%;
            select{padding: 0 25px 0 10px !important;}
        }
        a.viewupload{width: 60px;}
    }
    .updownBtn {
        display: flex;
        position: absolute;
        right: 30px;
        top: 10px;
        .form-field {
            width: 50px;
            a {
                background: #fff;
                border: 1px solid #CCCCCC;
                display: block;
                border-radius: 5px;
                text-align: center;
            }
            svg {
                width: 22px;
                height: 22px;
            }
            &:first-child{
                margin-right: 10px;
            }
        }
    }
    table.table.totalTable.accFourTotal{
        td:first-child {
            min-width: 246px;
        }
        td:nth-child(2) {
            min-width: 114px;
            padding: 0;
        }
        td:nth-child(3) {
            min-width: 133px;
        }
    }
    .activeRow.row.lastChildRemark .col-md-6:nth-child(3), .remarkBox .col-md-4:last-child {
        display: flex;
    }
    
    .activeRow.row.lastChildRemark .col-md-6:nth-child(3) td.border-bottom-0, .remarkBox .col-md-4:last-child td {
        display: grid;
        width: 100%;
    }
    
    .activeRow.row.lastChildRemark .col-md-6:nth-child(3) th, .remarkBox .col-md-4:last-child th{
        margin-top: 8px;
    }
}

.customTable{
    border: 10px solid;
    border-radius: 20px;
    border-color: #1b2f68;
    background-color: transparent;
    margin: 30px auto;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0px 0px 36px 15px rgba(0, 0, 0, 0.28);
}
.layoutFixedTable{
    table-layout: fixed;
}