.map_chart{
    position: relative;
    height: 625px;
    svg {
        vertical-align: middle;
        // left: -25.5%!important;
        width: 760px;
        top: -11%!important;
    }
    .HoverInfo{
        margin-top: -50px;
        .amountValue{
            line-height: 0;
            padding: 0 20px 15px 20px;
            .rupeeIcn{
                color:#F84E33;
                font-size: 20px;
                font-weight: 600;
                display: block;
                svg{
                    color:#F84E33;
                    height: 40px;
                    width: 22px;
                }
            }
        }
    }
}

.fundUtilization{
    .doughnutChart{
        max-width: 376px;
        max-height: 376px;
        padding: 20px 50px;
    }
    .doughnutChartFund{
        margin-top: 36px;
        .FundsSanctioned, .FundsUtilized, .FundsPending{
            border-left: 4px solid rgba(146, 100, 191, 1);
            padding-left: 15px;
            margin-bottom: 33px;
            h4 {
                color: rgba(146, 100, 191, 1);
                margin-bottom: 0;
            }
            
            p {
                font-weight: 600;
                color: rgba(50, 50, 50, 1);
                margin-bottom: 0;
            }
        }
        .FundsUtilized{
            border-color: rgba(16, 170, 144, 1);
            h4{
                color:rgba(16, 170, 144, 1)
            }
        }
        .FundsPending{
            border-color: rgba(45, 82, 187, 1);
            h4{
                color: rgba(45, 82, 187, 1);
            }
        }
    }
}
.SummeryChart{
    .apexcharts-canvas{
        top: -30px;
        top: -30px;
        transform: scale(-1, 1);
    }
    .apexcharts-legend.position-bottom.center, .apexcharts-legend.position-top.center{
        justify-content: space-around;
        inset: auto 0px 25px -4px!important;
        // margin-left: -38px;
        transform: scale(-1, 1);
    }
    .apexcharts-inner.apexcharts-graphical {
        transform: scale(1.7);
    }
}
.FundChart{
    margin-top: 40px;
    .apexcharts-legend{
        inset: auto 0px 10px !important;
        bottom: 0;
        .apexcharts-legend-series{
            line-height: 18px;
        }
    }
    & > div{
        min-height: 220px!important;
    }
}
.AssetsChart{
    & +  h6{
        font-size: 14px;
    text-align: center;
    margin-top: -35px;
    }
}
.PQChart{
    .apexcharts-inner {
        transform: scale(2.85) translate(-4px, 1px);
    }
}
.LossReduction{
    margin-top: -20px;
}
.smartMeteringLabel{
    padding: 0;
    list-style-type: none;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    margin: -40px auto 34px;
}