.app-discomir {
    .gp-heading {
        color: var(--sttl-font-secondary);
    }

    .app-discomir-report {
        .report-content-heading {
            border-bottom: 1px solid #d3d3d3;
            line-height: 28px;
        }

        .report-content {
            table {
                border-collapse: separate;
                border-spacing: 0;
            }

            table th {
                border-bottom: 1px solid #dee2e6;
                border-right: 0px;
            }

            table tr:first-child th {
                border-top: 1px solid #dee2e6;
            }

            table td {
                border-bottom: 1px solid #dee2e6 !important;
                border-right: 0px;
            }

            table tr:first-child td {
                border-top: 1px solid #dee2e6;
            }

            table th:first-child,
            table td:first-child {
                border-left: 1px solid #dee2e6;
            }

            table th:last-child,
            table td:last-child {
                border-right: 1px solid #dee2e6;
            }
        }
    }
}