:root {
  --sttl-font-light: 300;
  --sttl-font-medium: 500;
  --sttl-font-bold: 600;
  --sttl-font-bolder: 700;
  --sttl-font-white: #fff;
  --sttl-font-black: #000;
  --sttl-font-green: #00c056;
  --sttl-font-red: #ff0000;
  // --sttl-font-primary: #F58216;
  --sttl-font-primary: #B55B12;
  --sttl-font-secondary: #1B2F68;
  --sttl-position-absolute: absolute;
  --sttl-font-size-52: 42px;
  --sttl-font-size-30: 30px;
  --sttl-font-size-26: 26px;
  --sttl-font-size-23: 23px;
  --sttl-font-size-20: 20px;
  --sttl-font-size-18: 18px;
  --sttl-font-size-16: 16px;
  --sttl-font-size-14: 14px;
  --sttl-font-size-12: 12px;
}

:root .dark-mode {
  --sttl-font-white: #000;
}

:root .f-small {
  --sttl-font-size-52: 32px;
  --sttl-font-size-30: 25px;
  --sttl-font-size-26: 20px;
  --sttl-font-size-23: 18px;
  --sttl-font-size-20: 16px;
  --sttl-font-size-18: 15px;
  --sttl-font-size-16: 14px;
  --sttl-font-size-14: 12px;
  --sttl-font-size-12: 10px;
  --bs-body-font-size: 14px;
}

:root .f-plus {
  --sttl-font-size-52: 52px;
  --sttl-font-size-30: 35px;
  --sttl-font-size-26: 30px;
  --sttl-font-size-23: 25px;
  --sttl-font-size-20: 24px;
  --sttl-font-size-18: 22px;
  --sttl-font-size-16: 20px;
  --sttl-font-size-14: 16px;
  --sttl-font-size-12: 14px;
  --bs-body-font-size: 20px;
}