.modal{
    .modal-dialog{
        max-width: 1250px;
        .modal-content{
            border-radius: 0;
            .modal-header {
                background: var(--sttl-font-secondary);
                color: var(--sttl-font-white);
                border-radius: 0;
                h2{
                    font-size: var(--sttl-font-size-18);
                }
                button.btn-close {
                    background-color: var(--sttl-font-white);
                    opacity: 1;
                    border-radius: 100%;
                    background-size: 10px;
                    width: 10px;
                    height: 10px;
                }
            }
            .leftSection{
                border-right: 1px solid #ccc;
            }
            a.text-success .gr-icon {
                font-size: 20px;
                path{
                    stroke:var(--sttl-font-green)
                }
            }
            .gr-icon {
                font-size: 18px;
                path{
                    stroke:var(--sttl-font-red)
                }
            }
            table{
                thead{
                    text-align: center;
                    vertical-align: middle;
                    background-color: var(--sttl-font-secondary)!important;
                    th{
                        color: var(--sttl-font-white) !important;
                        font-weight: 400!important;
                    }
                    tr{
                        background-color: var(--sttl-font-secondary)!important;
                        th{
                            color: var(--sttl-font-white) !important;
                            font-weight: 400!important;
                        }
                    }
                }
                tr{
                    td{
                        font-size: var(--sttl-font-size-14);
                        input{
                            min-width: 90px!important;
                        }
                    }
                    &:nth-child(even) {
                        background-color: #ecf1f9;
                    }
                }
            }
        }
    }
}
.table-responsive.paymentModalTable table th:last-child, .table-responsive.paymentModalTable table td:last-child {
    max-width: 30px;
}