.app-csrd {
    .form-group.hidden {
        display: none;
    }

    // .form-control.is-invalid {
    //     background-image: unset;
    // }
    .app-csrd-mis-report {
        .mis-report-content-heading {
            border-bottom: 1px solid #d3d3d3;
            line-height: 28px;
        }

        .mis-report-content {
            table {
                border-collapse: separate;
                border-spacing: 0;
            }

            table th {
                border-bottom: 1px solid #dee2e6;
                border-right: 0px;
            }

            table tr:first-child th {
                border-top: 1px solid #dee2e6;
            }

            table td {
                border-bottom: 1px solid #dee2e6 !important;
                border-right: 0px;
            }

            table tr:first-child td {
                border-top: 1px solid #dee2e6;
            }

            table th:first-child,
            table td:first-child {
                border-left: 1px solid #dee2e6;
            }

            table th:last-child,
            table td:last-child {
                border-right: 1px solid #dee2e6;
            }
        }
    }
}

.generalInstruction {
    h3 {
        font-weight: 700;
        color: var(--sttl-font-primary);
    }

    h6 {
        font-size: var(--sttl-font-size-18);
        font-weight: var(--sttl-font-bold);
        color: var(--sttl-font-secondary);
    }

    ul {
        li {
            margin-bottom: 5px;

            span {
                display: block;
            }

            img {
                border: 1px solid #aaa;
                margin: 10px 0;
                padding: 10px;
            }
        }
    }
}

.text-justify {
    text-align: justify;
}

.app-csrd {
    .TabButtons {
        ul {
            li {
                &:first-child {
                    display: none;
                }
            }
        }
    }
}