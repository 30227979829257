@import '../../css/mixin';
.InnerBanner{
    // background-image: url(../../images/inner-banner.jpg);
    // height: 100px;
    margin-bottom: 0px;
    background-size: cover;
    display: flex;
    align-items: center;
    h2 {
        // color: var(--sttl-font-white);
        @include customFont(28px);
        font-weight: 500;
        margin-bottom: 5px;
    }
    ul{
        margin-top:20px;
        li{
            display: inline-block;
            padding: 0 10px;
            @include customFont(16px);
            // color: var(--sttl-font-white);
            font-weight: 500;
            position: relative;
            &:first-child{
                padding-left: 0;
            }
            &::before{
                content: '>';
                position: absolute;
                right: -3px;
                top: 1px;
            }
            &:last-child{
                &::before{
                    display: none;
                }
            }
            a{
                color: #000;
                font-weight: 400;
                &:hover{
                    color:var(--sttl-font-primary)
                }
            }
        }
    }
}
