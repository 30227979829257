@import './_mixin.scss';


@media (max-width: 1699px) {
  .mainWrap.dashboard {
    .dashboardInner.detailedDashboard {
      .row.topStripBox {
        .col-md-2 {
          width: 15%;

          &+.col-md-2 {
            width: 15%;
          }
        }

        .col-md-4 {
          width: 35%;

          &+.col-md-4 {
            width: 35%;
          }
        }
      }

      .dashBox {
        h4 {
          font-size: 18px;
        }

        h6 {
          font-size: 14px;

          &+button {
            margin-top: -8px;
            padding-right: 5px;

            svg {
              font-size: 14px;
            }
          }
        }

        .pqBox {
          padding: 0 7px;

          &:last-child {
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
          }
        }

        .pqMainBox {
          width: 75%;
        }

        .pqChartBox {
          width: 25%;
        }

        span.modalIcon svg {
          font-size: 18px;
        }
      }

      .FundChart {
        &>div {
          margin-top: 15px;
        }

        .apexcharts-legend {
          inset: auto 0px 0 !important
        }
      }

      .lossChartDiv {
        h5 {
          padding: 8px 0px 8px 10px;
          font-size: 14px;
        }

        .value {
          width: 80%;
        }

        .imgHead {
          width: 20%;
        }
      }
    }
  }

  .mainWrap.dashboard .dashboardInner .discomSection .discomRight .apexchartsLegendCustom>div span {
    margin-left: 5px;
  }
}

@media (min-width: 1500px) {
  .discomRight {
    .dashChart {
      .DiscomSummary {
        .row {
          .col-xxl-6 {
            flex: 0 0 auto;
            width: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 1499px) {
  .dashboardInner.detailedDashboard {
    .dashBox {
      &>.d-flex.justify-content-between.w-100 {
        align-items: center;
      }

      .dashBox-1 {
        width: 100%;

        .headModal {
          display: flex;
          justify-content: space-between;
        }

        .postionTooltip {
          button {
            position: absolute;
            top: 15px;
            right: 0;
          }
        }

        &:first-child {
          border-bottom: 1px solid #bfbfbf;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }
      }

      .dashBox-2 {
        .pqBox {
          flex-wrap: wrap;
          justify-content: center;

          .pqBoxIcn {
            width: 55px;
            margin-bottom: 10px;
          }

          .pqBoxIcn+div.w-50 {
            min-width: 100%;
            text-align: center;

            p {
              br {
                display: none;
              }
            }
          }

          p {
            font-size: 11px !important;
          }
        }
      }

      .pqMainBox {
        max-width: 60%;
        border-right: 1px solid #bfbfbf;
        padding-right: 20px;

        .d-flex.dashBox-3 {
          flex-wrap: wrap;

          .pqBox.w-50 {
            min-width: 100%;
            padding: 0;
            border: 0
          }
        }
      }

      .pqChartBox.pqBox.ps-0 {
        width: 40%;
        justify-content: center;
      }
    }
  }

  .discomLeft {
    margin-bottom: 20px;

    .mapSection {
      .IndiaSvg {
        margin-top: 0 !important;

        svg {
          max-height: 460px;
        }
      }
    }
  }

  .smartMeteringChart {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;

    h4 {
      font-size: 17px;
      color: #1B2F68;
      font-weight: 600;
      margin-bottom: 10px;
    }

  }

  .discomRight {
    .dashChart {

      .DiscomSummary,
      .ATcLoss {
        .row {

          .col-xxl-6,
          .col-xxl-8 {
            flex: 0 0 auto;
            width: 100%;
          }
        }
      }
    }

    .apexchartsLegendCustom {
      font-size: 10px !important;
    }
  }
}

@media (min-width: 1400px) {
  body .container {
    max-width: 1416px;
    width: 100%;
  }

  body .SummeryChart .apexcharts-inner.apexcharts-graphical {
    transform: scale(1.5);
  }
}

@media (max-width: 1399px) {
  .mainWrap.dashboard {
    .dashboardInner.detailedDashboard {
      h2 {
        font-size: 24px;
      }

      .row.topStripBox {
        .col-md-2 {
          width: 12%;
        }

        .col-md-4 {
          width: 37%;

          &+.col-md-4 {
            width: 36%;
          }
        }
      }

      .dashBox {
        padding: 15px 10px;

        h6 {
          font-size: 16px;
        }

        h4 {
          font-size: 18px;
        }

        .pqBox {
          padding: 0 7px;

          &:last-child {
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

    .discomLeft {
      .stateViewDiscomList {
        a {
          font-size: var(--sttl-font-size-12);
        }
      }

      .dashBox {
        p {
          font-size: 16px;
        }

        h5 {
          font-size: 14px !important;
        }

        img {
          width: 35px;
        }
      }

    }

    .dashChart {
      .AssetsChart {
        &+h6 {
          font-size: var(--sttl-font-size-12);
        }
      }
    }
  }

  .loginWrap {
    height: calc(100vh - 245px);
  }

  .bannerSection {
    .quote {
      padding: 25px 40px;

      .quoteInner p {
        font-size: var(--sttl-font-size-20);
      }
    }
  }

  .reportingSection {
    .reportingInner {
      p {
        font-size: var(--sttl-font-size-14);
      }
    }
  }

  .reportSection .pressSwiper {
    max-height: 300px;
  }

  .SchemeOverviewSection {
    .imgWrap {
      position: relative;
      height: 300px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .clamHeading {
    display: flex;

    .d-flex {
      width: 50%;
      justify-content: flex-end;
    }
  }

  .dashboardInner {
    .map_chart {
      height: 554px;

      svg {
        width: 100%;
      }
    }

    .DiscomSummary {
      h4 {
        br {
          display: none;
        }
      }
    }
  }
  .financialMainFrom.inputFromNew .tab-content .formBox select, .financialMainFrom.inputFromNew .tab-content .formBox input, .mainWrap.inputFromNew .tab-content .formBox select, .mainWrap.inputFromNew .tab-content .formBox input{font-size: 10px;}
  .financialMainFrom.inputFromNew .tab-content .formBox label, .mainWrap.inputFromNew .tab-content .formBox label{font-size: 12px;}
}

@media (max-width: 1366px) {
  .uploadUcss .form-group.file .fileUpload.position-relative.text-center.align-items-end .customUpload{
    width: 180px;
  }
  .dashboardInner.detailedDashboard {
    .dashBox {
      .dashBox-1 {
        .headModal {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }

  header {
    .mainHeader {
      padding: 5px 0;

      .logo {
        img.mainLogo {
          width: 135px;
        }

        img.recLogo {
          width: 110px;
        }

        img.pfcLogo {
          width: 65px;
        }
      }

      .navMenu ul.navbar-nav li.nav-item a.nav-link {
        @include customFont(14px);

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .loginBtn {
        .nav-link {
          @include customFont(14px);
          padding: 3px 10px;
        }
      }
    }
  }

  // section.bannerSection.schemeSection {
  //   padding: 30px 0;
  // }

  .bannerSection {

    // height: 180px;
    h1 {
      @include customFont(32px);
      line-height: 40px;
    }

    .imgWrap {
      width: 150px;
    }

    .imgWrap {
      bottom: -36px;
    }

    .quote {
      height: 120px;
      padding: 15px 40px;
      bottom: -36px;

      .quoteInner {
        p {
          @include customFont(16px);
        }

        p.pmName {
          @include customFont(14px);
        }

        p.pmTag {
          @include customFont(12px);
        }
      }
    }
  }

  .AssetsChart {
    &>div {
      margin-left: -35px
    }
  }

  // .schemeSection {
  //   h2 {
  //     @include customFont(24px);
  //   }

  //   padding: 60px 0 0px;

  //   .schemeInner {
  //     margin-bottom: 0;

  //     img {
  //       height: 150px;
  //     }

  //     h5 {
  //       @include customFont(18px);
  //       padding: 15px;
  //     }
  //   }

  //   .swiper {
  //     padding-bottom: 50px;
  //   }
  // }

  .reportingSection {
    padding: 30px 0 30px;

    h2 {
      @include customFont(24px);
    }
  }

  .reportSection {
    padding: 30px 0;

    .reportHeading {
      h2 {
        @include customFont(24px);
      }
    }

    .pressRelease .pdfDetails p {
      @include customFont(15px);
    }
  }

  .aboutAccordioin .TabButtons ul li a {
    padding: 10px 25px;
    font-size: var(--sttl-font-size-14);
  }

  .aboutAccordioin .Accordion-group .Tab-panel .tabPanelInner h5 {
    font-size: 1rem;
  }

  .aboutAccordioin .Accordion-group .Tab-panel .tabPanelInner svg {
    width: 50px;
    display: block;
    margin: 0 auto 0px;
  }

  .aboutAccordioin .TabButtons ul {
    margin: 0;
  }

  .aboutAccordioin .Accordion-group .Tab-panel .tabPanelInner {
    padding: 43px 0;
  }

  .aboutAccordioin .Accordion-group .Tab-panel {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .InnerBanner h2 {
    font-size: var(--sttl-font-size-23);
  }

  .login-main {
    padding: 100px 0;
  }

  .financialForm {
    .radioYear {
      padding: 0;

      label {
        @include customFont(14px);

        &.h6 {
          @include customFont(14px);
        }
      }

      select {
        height: 30px;
      }

      .inputBtn {
        width: 25px;
        height: 25px;
        line-height: 24px;

        img {
          width: 15px;
        }
      }

      input[type="radio"] {
        margin-top: 4px;
      }

      svg {
        font-size: 22px;
      }
    }

    .financialMainFrom {
      .tab-content {
        .accordion {
          .accordion-item {
            padding: 5px 0;
            .col-md-2.float-end{
              top: 5px;
          }

            h3 button {
              @include customFont(18px);
            }
          }
        }

        .RevenueDetailsTable {
          th, td {
            font-size: 12px!important;
          }

          tr {
            td {
              padding: 10px 3px !important;
              @include customFont(12px);

              input,
              select, .view-field {
                font-size: 10px !important;
                // height: 30px;
                // padding: 0 5px;
                // line-height: 30px; 
              }
              .form-group.text, .form-group.select{
                .view-field {
                  height: 100%;
                  padding: 0;
                  line-height: 14px;
                }
              }

              .fileUpload .customUpload,
              .fileUpload .viewupload {
                // height: 30px;
                // line-height: 30px;
                padding: 0 0;
              }
            }
          }
        }
      }

      ul.nav-tabs li .nav-link {
        padding: 10px;
        @include customFont(15px);

        &.active {
          padding: 10px;
        }
      }
    }
  }

  .StepperContainer-0-2-1 .StepButton-0-2-4 {
    width: 30px;
    height: 30px;

    span {
      @include customFont(14px);
    }
  }

  .StepperContainer-0-2-1 .StepButton-0-2-4+div>span {
    font-size: var(--sttl-font-size-16);
  }

  .formBtnSection a,
  .formBtnSection button {
    font-size: var(--sttl-font-size-14);
    padding: 5px 20px;
  }

  .mainWrap.dashboard .dashboardInner .GrantsUtilized h3 {
    font-size: var(--sttl-font-size-23);
  }
  .aboutAccordioin{
    .Accordion-group{
        .Tab-panel{    
            .actionWrap.energyActionBox{
                .ActionCenterSection{
                    .row.gx-0{
                        .col-lg-3.col-3.d-flex.justify-content-center {
                            .tabPanelInner{
                                img{
                                    height: 45px;        
                                }
                                padding: 40px 0px;
                                h5{
                                    font-size: 14px;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        } 
    } 
}

}

@media (max-width: 1199px) {
  .financialMainFrom.inputFromNew .radioTitleSection, .mainWrap.inputFromNew .radioTitleSection{border-bottom: 0;}
  .responsiveFixWidth {
    overflow-x: auto;

    table {
      width: 1150px;
    }
  }

  .table-responsive {
    overflow-x: auto;

    table.table {
      min-width: 1400px;
    }
  }

  .navbar {
    .offcanvas.offcanvas-start {
      width: 100%;
      padding: 30px;

      .btn-close.d-xl-none {
        background-color: var(--sttl-font-primary);
        padding: 20px;
        border-radius: 100%;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }

  .reportingSection {
    .reportingInner {
      margin-bottom: 25px;

      img {
        width: 100%;
      }

      p br {
        display: none;
      }
    }
  }

  .pressRelease {
    .pdfDetails {
      width: 100%;
    }
  }

  .aboutAccordioin {
    .TabButtons {
      max-width: 35%;
    }

    .Accordion-group {
      max-width: 65%;
    }
  }

  .SchemeOverviewSection {
    .imgBox {
      p {
        padding-right: 0 !important;
        margin-top: 30px;
      }
    }

    .part.partA {
      padding-right: 0 !important;
    }
  }

  .clamHeading {
    flex-wrap: wrap;

    .d-flex {
      width: 100%;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }

  .sidebarSection {
    display: none;
  }

  .mainWrap.dashboard .dashboardInner {
    .GrantsUtilized .GrantsUtilizedInner .content h4 {
      font-size: 34px;
    }

    .discomSection .discomRight {

      .DiscomSummary,
      .ATcLoss {
        padding: 20px;
      }
    }
  }

  .mainWrap.dashboard {
    .dashboardInner {
      .discomSection {
        .discomLeft {
          .mapSection {
            .table-responsive table.table {
              min-width: 100%;
            }
          }
        }

        .discomRight {}
      }

      .GrantsUtilized {
        padding: 20px !important;

        .GrantBox {
          padding: 20px 10px;
          flex-wrap: wrap;
          height: 170px;

          p {
            font-size: 14px;
          }

          h3 {
            font-size: var(--sttl-font-size-20);
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .loginWrap {
    height: calc(100vh - 315px);

    .login-main {
      .loginPosition {
        height: calc(100vh - 315px);
      }
    }
  }

  body .container {
    width: 100%;
    max-width: 100%;
    padding: 0 30px;
  }

  .topHeader {
    ul {
      text-align: center;
    }
  }

  .logo {
    .mainLogo {
      width: 150px;
    }

    .recLogo {
      width: 110px;
    }

    .pfcLogo {
      width: 80px;
    }
  }

  .navMenu {
    flex-direction: row-reverse;
  }

  .bannerSection {
    h1 {
      font-size: 28px;
    }

    .imgWrap {
      display: none;
    }

    .quote {
      padding: 10px 0px 10px 40px;
      height: 110px;
      bottom: -20px;

      .quoteInner {
        padding: 0 0px;
      }
    }
  }

  body .reportingSection .reportingInner p {
    padding: 10px 20px;
    height: 66px;
  }

  // .schemeSection .schemeInner h5 {
  //   position: unset !important;
  //   font-size: 16px;
  //   padding: 10px;
  // }

  .footerInner {
    flex-wrap: wrap;

    .copyRight {
      width: 100%;
      margin-bottom: 20px;

      ul {
        margin-bottom: 0px;
      }
    }
  }

  .InnerBanner {
    h2 {
      font-size: var(--sttl-font-size-26) !important;
    }
  }

  .aboutAccordioin {
    .TabButtons {
      max-width: 40%;
    }

    .Accordion-group {
      max-width: 60%;

      .Tab-panel {
        background-size: cover;

        .tabPanelInner {
          padding: 50px 0;
        }
      }
    }
  }

  .notFound {
    height: auto;
    margin-top: 50px;

    img {
      width: 100%;
    }

    .backBtn {
      background: var(--sttl-font-secondary);
      color: var(--sttl-font-white);
      padding: 10px 25px;
      border-radius: 100px;
      margin: 0 auto;
      font-size: var(--sttl-font-size-14);

      &:hover {
        color: var(--sttl-font-secondary);
        background: transparent;
        border: 1px solid var(--sttl-font-secondary);
      }
    }
  }

  .rdssGuideline {
    .nav-tabs {
      border: 0;

      .nav-item {
        .nav-link {
          padding: 20px 0px;
        }
      }
    }

    .tab-content {
      padding: 30px 20px;
    }
  }

}

@media (max-width: 768px) {
  .loginWrap {
    height: calc(100vh - 280px);

    .login-main {
      .loginPosition {
        height: calc(100vh - 280px);
      }
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
  }

  .topHeader {
    display: none;
  }

  header {
    .container {
      padding: 0 15px;
    }

    .searchBar {
      display: block !important;
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 56px;
      height: 40px;
      border-radius: 5px;
      line-height: 36px;
      text-align: center;
      margin-left: 15px;

      svg {
        color: var(--sttl-font-black);
      }
    }
  }

  .bannerSection {
    background-size: cover;
    position: relative;
    background-position: top;

    h1 {
      line-height: 35px;
    }

    .quote {
      height: auto;
      margin: 0;

      .quoteInner {
        p {
          font-size: var(--sttl-font-size-18);
        }

        p.pmName {
          font-size: var(--sttl-font-size-16);
        }
      }
    }
  }

  // .schemeSection {
  //   padding: 40px 0 0 0;

  // }

  .InnerBanner {
    h2 {
      font-size: var(--sttl-font-size-23) !important;
    }
  }

  .socialSection {
    margin-bottom: 20px;
  }

  .aboutAccordioin {
    flex-wrap: wrap;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.11%);
    border-radius: 10px;
    padding: 20px 0px;

    .Accordion-group {
      max-width: 100%;

      &[open] {
        transition: 0.3s all ease-in-out;

        .Accordion-head {
          background-color: var(--sttl-font-primary);
        }
      }

      .Accordion-head {
        color: #323232;
        font-weight: var(--sttl-font-medium);
        font-size: var(--sttl-font-size-18);
        padding: 15px 30px;
      }

      #ActionCentre {
        .ActionCenterSection {
          background-image: url(../images/about-tab.jpg);
          overflow: hidden;
          background-size: cover;
          margin-bottom: 25px;
        }

        .tabPanelInner {
          padding: 40px 0;
          width: 100%;
          text-align: center;
          height: 100%;
          border: 1px solid rgba(156, 156, 156, 0.15);
          transition: 0.3s all ease-out;
          position: relative;

          h5 {
            color: var(--sttl-font-white);
            margin-top: 10px;
          }

          .hoverContent {
            padding: 0 20px;
            position: absolute;
            text-align: center;
            width: 100%;
            display: none;
          }

          .hoverContent p {
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            font-size: var(--sttl-font-size-14)
          }

          &:hover {
            p {
              opacity: 1;
              visibility: visible;
              color: var(--sttl-font-white)
            }
          }
        }
      }

      .SchemeOverviewSection {
        padding: 50px;
      }
    }
  }

  .discomLeft .mapSection .IndiaSvg svg {
    height: 580px;
  }

  .StepperContainer-0-2-1 {
    overflow-x: auto;
  }

  .mainWrap.dashboard .dashboardInner {
    .heading {
      h2 {
        font-size: var(--sttl-font-size-23);
      }
    }

    .discomSection {
      .discomRight {
        .dashChart {
          max-width: 100%;
        }
      }
    }

    .stripSection h5 {
      font-size: var(--sttl-font-size-18);
    }
  }

  .rdssGuideline {
    margin: 30px;
  }

  footer {
    padding: 11px 0;
  }

}

@media (max-width: 699px) {
  .mainWrap.dashboard .dashboardInner {
    .map_chart {
      min-height: 450px;
      height: 100%;
    }
  }
}

@media (max-width: 575px) {
  body .container {
    padding:0  15px;
  }

  // section.bannerSection.schemeSection {
  //   padding: 11px 0;
  // }

  .mainHeader {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .navMenu {
      justify-content: space-between;
    }
  }

  .mediaGallery {
    img {
      width: 100%;
    }
  }

  .reportingSection,
  .reportSection {
    padding: 20px 10px;
  }

  .footerInner {
    flex-wrap: wrap;

    .copyRight {
      .visitor.text-end {
        width: 100%;
      }
    }
  }

  .year {
    label {
      font-size: var(--sttl-font-size-14) !important;
    }
  }

  .StepperContainer-0-2-1 {
    padding: 24px 0 !important;

    .StepButton-0-2-4+div>span {
      font-size: var(--sttl-font-size-14);
    }

    overflow-x: auto;
  }

  .formBtnSection {
    flex-wrap: wrap;

    a,
    button {
      margin-bottom: 10px !important;
      display: inline-block;
    }
  }
  .resMinWidth {
    min-width: 900px;
  }

  .aboutAccordioin {
    .Accordion-group {
      .SchemeOverviewSection {
        padding: 20px;

        .heading {
          h2 {
            font-size: var(--sttl-font-size-23) !important;
          }

          p {
            font-size: var(--sttl-font-size-18) !important;
          }
        }

        .imgBox {
          p {
            margin-top: 10px;
            font-size: var(--sttl-font-size-14) !important;
            line-height: var(--sttl-font-size-23) !important;
          }
        }

        .schemeFeature {
          padding: 20px !important;

          h3 {
            font-size: var(--sttl-font-size-20) !important;
            line-height: var(--sttl-font-size-26) !important;
          }

          p {
            font-size: var(--sttl-font-size-16) !important;
            line-height: var(--sttl-font-size-20) !important;
          }

          h6 {
            font-size: var(--sttl-font-size-16) !important;
            line-height: var(--sttl-font-size-23) !important;
          }
        }

        .contentSec {
          h4 {
            font-size: var(--sttl-font-size-18) !important;
            font-weight: var(--sttl-font-medium);
          }

          .part {
            padding: 0 !important;
            border: 0 !important;

            p {
              font-size: var(--sttl-font-size-16) !important;
            }
          }
        }

        ul {
          li {
            font-size: var(--sttl-font-size-14) !important;

            &::before {
              top: 10px !important;
            }
          }
        }
      }
    }
  }

  .dashboardInner {
    .discomSection {
      .discomLeft {
        .heading {
          .radioSelect {
            flex-wrap: wrap;
            margin-top: 12px;

            select.form-select.w-25 {
              margin-top: 15px;
              width: 200px !important;
            }
          }
        }

        .mapSection {
          .map_chart {
            min-height: 400px;
            height: 400px;
          }
        }
      }
    }
  }

  .IndiaSvg {
    svg {
      height: auto;
    }
  }

  .mainWrap.dashboard .dashboardInner.detailedDashboard .consumer {
    padding: 20px;

    .smallLineChart {
      padding: 10px;
    }
  }

  .rdssGuideline {
    margin: 10px;

    .nav-tabs .nav-item .nav-link {
      padding: 10px 0px;
    }
  }
}

@media (max-width: 499px) {
  .logo {
    .mainLogo {
      width: 120px;
    }

    .recLogo {
      width: 100px;
    }

    .pfcLogo {
      width: 70px;
      padding-right: 0;
    }
  }

  .bannerSection {
    h1 {
      font-size: var(--sttl-font-size-26);
      line-height: 30px;
      padding-top: 15px;
    }

    .quote {
      height: auto;
      padding: 15px;

      .quoteInner {
        padding: 0;

        p {
          font-size: var(--sttl-font-size-16);

          &::before,
          &::after {
            display: none;
          }
        }

        p.pmTag {
          padding: 0;
        }
      }
    }
  }

  .reportingSection {
    .row {
      .col-6 {
        width: 100%;
      }
    }
  }

  #ActionCentre {
    .tabPanelInner {
      padding: 30px 0;

      svg {
        width: 50px;
        height: 50px;
      }

      h5 {
        font-size: var(--sttl-font-size-16);
        font-weight: 400;
      }
    }
  }

  .InnerBanner {
    h2 {
      margin-bottom: 0 !important;
      font-size: var(--sttl-font-size-23);
    }

    .BreadCumb {
      display: none;
    }
  }

  .dashboardInner {
    .discomSection {
      .discomLeft {
        .heading {
          .radioSelect {
            flex-wrap: wrap;
            margin-top: 12px;

            select.form-select.w-25 {
              margin-top: 15px;
              width: 200px !important;
            }
          }
        }

        .mapSection {
          .map_chart {
            min-height: 350px;
            height: 350px;
          }
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width:499px) {
  .dashboardInner {
    .discomSection {
      .discomLeft {
        .mapSection {
          .map_chart {
            min-height: 300px;
            height: 300px;
          }
        }
      }
    }
  }
}

@media (max-height: 680px) {
  .loginWrap {
    height: calc(100vh - 169px);

    .login-main {
      .loginbox {
        transform: translateY(-50%) scale(0.8);
        padding: 10px 20px;

        .form-field {
          margin-bottom: 10px;
        }

        input {
          height: 34px;
          border-radius: 0;
        }
      }
    }
  }

  footer {
    padding: 10px 0;
  }
}