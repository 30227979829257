.text-primary{
    color: #1b2f68 !important;;
}
.PqTable thead th {
    position: sticky;
    top: 0;
    background: #fff;
}
.customTableWidth{
    table{
        tr{
            td{
                &:first-child, &:nth-child(2), &:nth-child(4), &:nth-child(5), &:nth-child(6){min-width: 130px!important;width: 130px!important;}
                &:nth-child(3){min-width: 150px!important;width: 150px!important;}
            }
        }
    }
}
.financialMainFrom.inputFromNew{
    .sancLetter.uploadSelect {
        .fileUpload{
            .downEqule{
                input {
                    margin: 0!important;
                }
                .customUpload {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    background: #ecf1f9!important;
                    color: #1b2f68 !important;
                }
                
                select {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            } 
            a.viewupload {
                margin: 0 0 0 -4px !important;
                border-top-left-radius: 0!important;
                border-bottom-left-radius: 0!important;
                color: #009300;
            }
        }    
    }
}
.fdLinkTable, .other_documents{
    .form-group.file{
        .form-field{
            display: block!important;
            .downEqule{
                display: flex;
                align-items: center;
                .customUpload, input {
                    width: 45px!important;
                    border-top-right-radius: 0!important;
                    border-bottom-right-radius: 0!important;
                }
                .customUpload{
                    position: absolute!important;
                    background: #ecf1f9!important;
                    color: #1b2f68 !important;
                }
                select{
                    min-width: 120px;
                    max-width: 120px;
                    border-top-left-radius: 0!important;
                    border-bottom-left-radius: 0!important;
                    height: 40px;
                }
            }
            a.viewupload {
                margin: 0 0 0 -4px !important;
                border-top-left-radius: 0!important;
                border-bottom-left-radius: 0!important;
                color: #009300;
                max-width: 45px!important;
                position: relative; 
                z-index: 1;
            }
        }
    }
}
.authorisedTable{
    .form-group.file{
        .form-field{
            display: flex!important;
        }
    }
    .field-comp-multiselect{
        .css-1fdsijx-ValueContainer{
            padding: 0 10px!important;
            height: 40px!important;
            .css-1jqq78o-placeholder{
                margin: 0;
                line-height: 40px;
                font-size: 12px;
            }
            .css-qbdosj-Input{
                margin: 0;
                padding: 0;
            }
        }
        .css-1u9des2-indicatorSeparator{
            display: none;
        }
        .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer:first-child {
            position: absolute;
            top: 0;
            background: red;
            padding: 0;
            color: #fff;
            right: 0;
            border-radius: 0 3px 0px 5px;
            width: 20px;
            svg{
                width: 15px;
                margin: 0 auto;
            }
        }
    }    
}
.css-r7leb8-MenuPortal{
    font-size: 12px!important;
}
.formLabel{
    label{
        font-size: 12px;
        font-weight: 500;
    }
    input{
        font-size: 12px;
        max-height: 30px;
    }
}
.sperator {
    background: #1b2f6808;
    height: 20px;
    margin: 40px auto;
    display: block;
}
.pfForm{
    .form-group{
        margin-bottom: 15px;
        label{
            font-size: 14px;
            margin-bottom: 5px;
        }
        input{
            font-size: 12px;
            text-align: left!important;
        }
        .field-comp-multiselect{
            .css-1fdsijx-ValueContainer{
                padding: 0 10px!important;
                height: 40px!important;
                .css-1jqq78o-placeholder{
                    margin: 0;
                    line-height: 40px;
                    font-size: 12px;
                }
                .css-qbdosj-Input{
                    margin: 0;
                    padding: 0;
                }
            }
            .css-1u9des2-indicatorSeparator{
                display: none;
            }
            .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer:first-child {
                position: absolute;
                top: 0;
                background: red;
                padding: 0;
                color: #fff;
                right: 0;
                border-radius: 0 3px 0px 5px;
                width: 20px;
                svg{
                    width: 15px;
                    margin: 0 auto;
                }
            }
        }    
    }
    .form-group.file{
        margin-bottom: 0;
    }
    .downEqule{
        display: flex;
        align-items: center;
        .customUpload, input {
            width: 45px!important;
            border-top-right-radius: 0!important;
            border-bottom-right-radius: 0!important;
        }
        .customUpload{
            position: absolute!important;            
        }
        select{
            min-width: 120px;
            max-width: 120px;
            border-top-left-radius: 0!important;
            border-bottom-left-radius: 0!important;
            height: 40px;
        }
    }
    a.viewupload {
        margin: 0 0 0 -4px !important;
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
        color: #009300;
        max-width: 45px!important;
        position: relative; 
        z-index: 1;
    }
}
.clamFormOne{
    .td10, .td13, .td14, .td15, .td19, .td20{width: 150px;}
    .td11{width: 110px;}
    .td16{width: 140px;}
    .td17, .td18{width: 155px;}
}