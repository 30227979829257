.fixUploader{
    .form-group.file{
        .form-select, .customUpload{
            border-radius: 0!important;
        }
        .customUpload{
            background: #ecf1f9;
            margin: 0!important;
            svg{
                color: #1b2f68!important;
            }
        }
        .form-field {
            justify-content: start;
            .fileUpload{
                justify-content: start!important;
                width: auto!important;
                .dowupEqule{
                    max-width: 100%!important;
                    width: auto!important;                    
                }
                a{
                    svg{
                        color: #006c00;
                    }
                }
            }
            .view-form-field{
                width: 100%!important;
                select{
                    border-radius: 0;
                }
            }
            a.viewupload{                
                border-radius: 0!important;
                margin-left: 0!important;
                svg{
                    color: #006c00;
                }
            }
        }
    } 
} 

th.td101{min-width: 75px;max-width: 75px;width: 75px;}
th.td102{min-width: 120px;max-width: 120px;width: 120px;}
th.td103{min-width: 120px;max-width: 120px;width: 120px;}
th.td104{min-width: 120px;max-width: 120px;width: 120px;}
th.td105{min-width: 120px;max-width: 120px;width: 120px;}
th.td106{min-width: 120px;max-width: 120px;width: 120px;}
th.td107{min-width: 120px;max-width: 120px;width: 120px;}
th.td108{min-width: 120px;max-width: 120px;width: 120px;}
th.td109{min-width: 120px;max-width: 120px;width: 120px;}
th.td1010{min-width: 120px;max-width: 120px;width: 120px;}
th.td1011{min-width: 120px;max-width: 120px;width: 120px;}
th.td1012{min-width: 120px;max-width: 120px;width: 120px;}
th.td1013{min-width: 120px;max-width: 120px;width: 120px;}
th.td1014{min-width: 120px;max-width: 120px;width: 120px;}
th.td1015{min-width: 120px;max-width: 120px;width: 120px;}
.newTDWidth{
    .td1{min-width: 75px;max-width: 75px;width: 75px;}
    .td2{min-width: 120px;max-width: 120px;width: 120px;}
    .td3{min-width: 120px;max-width: 120px;width: 120px;}
    .td4{min-width: 120px;max-width: 120px;width: 120px;}
    .td5{min-width: 120px;max-width: 120px;width: 120px;}
    .td6{min-width: 120px;max-width: 120px;width: 120px;}
    .td7{min-width: 120px;max-width: 120px;width: 120px;}
    .td8{min-width: 120px;max-width: 120px;width: 120px;}
    .td9{min-width: 120px;max-width: 120px;width: 120px;}
    .td10{min-width: 120px;max-width: 120px;width: 120px;}
    .td11{min-width: 120px;max-width: 120px;width: 120px;}
    .td12{min-width: 120px;max-width: 120px;width: 120px;}
    .td13{min-width: 120px;max-width: 120px;width: 120px;}
    .td14{min-width: 120px;max-width: 120px;width: 120px;}
    .td15{min-width: 120px;max-width: 120px;width: 120px;}
    .td16{min-width: 120px;max-width: 120px;width: 120px;}
    .td17{min-width: 120px;max-width: 120px;width: 120px;}
    .td18{min-width: 120px;max-width: 120px;width: 120px;}
    .td19{min-width: 120px;max-width: 120px;width: 120px;}
}