div#root {
    justify-content: start;
}
.mainWrap {
    flex-grow: 1;
}
.financialForm.misNew {
    .financialMainFrom {
        position: relative;

        .radioTitleSection {
            margin-top: 20px;
            border-bottom: 2px solid #CED3DF;

            .radioYear {
                padding: 0;
                margin-top: 0;
                .form-check {
                    border-right: 1px solid #CED3DF;
                    padding-left: 20px;
                }
            }

            .row {
                .col-md-4 {
                    &:last-child {
                        .form-check {
                            border-right: 0;
                        }
                    }
                }
            }
        }
        .radioYear {
            border: 0;
            padding: 0 20px;
            .form-check {
                padding: 10px 0;
                display: flex;
                align-items: center;
                input[type="radio"] {
                    margin: 0 10px 0 0;
                }

                label {
                    font-size: var(--sttl-font-size-16);
                }
            }
        }

        .fYearMonth {
            position: absolute;
            top: 0;
            right: 0;
        }
        .fYearMonth + .radioYear{
            margin: 15px 0;
        }
        button.bsSearchBtn{
            margin-top: 15px;
            background-color: var(--sttl-font-primary);
            color:var(--sttl-font-white);
            border-radius: 7px;
            padding: 10px 30px;
            border:1px solid;
            &:hover{
                background-color:transparent;
                color: var(--sttl-font-primary);
                transition: 0.4s all ease-out;
            }
        }
        .dIcnBtn{
            margin-top: 15px;
        }
        a.bsSearchBtn{
            border-radius: 7px;
            padding: 10px 5px;
            background: transparent;
            border: 1px solid #aaa;
            margin-left: 10px;            
            width: 50px;
            display: inline-block;
            margin-top: 0;

            img{
                height: 24px;
            }
        }
    }
    .misBottomBtn{
        margin-bottom: 15px;
    }
    input{
        font-size: 14px;
    }
}
.btn-success{
    padding: 10px 20px;
    border-radius: 5px;
}
.pagination li.page-item button, .pagination li.page-item input {
    height: 40px;
}
@media (max-width:1199px){
    .financialForm.misNew {
        .financialMainFrom {
            .fYearMonth {
                position: unset;
                margin-top: 20px;
            }
        }
    }
}

.accordion{
    .accordion-item{
        border: 0;
        margin-top: 10px;
        button{
            background: #ecf1f9;
            color: #212530;
            font-weight: 500;
            border-bottom: 1px solid #ced3df;
            padding: 12px 20px;
            &:focus, &:focus-visible{
                outline: none;
                box-shadow: none;
            }
            &::after{
                margin: 0;
            }
            justify-content: space-between;
        }
        .accordion-body {
            padding: 10px 20px;
            background: #ecf1f9;
        }
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px;
    }
}
@media (min-width: 1400px) {
    body .container {
        max-width: 1416px;
        width: 100%;
    }
}