.app-rdss-qm {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  .label-h {
    font-weight: 600 !important;
    color: var(--sttl-font-secondary);
    font-size: var(--sttl-font-size-14);
  }

  .content-table {
    table {
      border-collapse: separate;
      // border-collapse: collapse;
      border-spacing: 0;
    }

    table th {
      border-bottom: 1px solid #dee2e6;
      border-right: 0px;
    }

    table tr:first-child th {
      border-top: 1px solid #dee2e6;
    }

    table td {
      border-bottom: 1px solid #dee2e6 !important;
      border-right: 0px;
    }

    table tr:first-child td {
      border-top: 1px solid #dee2e6;
    }

    table th:first-child,
    table td:first-child {
      border-left: 1px solid #dee2e6;
    }

    table th:last-child,
    table td:last-child {
      border-right: 1px solid #dee2e6;
    }
  }

  .app-rdss-qm-report {
    .financialForm .radioYear {
      height: auto;
      .form-check {
        padding-left: inherit !important;
      }
    }
  }

  .visit-image-viewer,
  .seal-image-viewer {
    [role="dialog"] {
      background-color: rgba(0, 0, 0, 0.8) !important;
    }

    [aria-hidden="true"] {
      display: block;
    }
  }
}
