body.dark-mode {
  background-color: #000;

  header {
    .topHeader {
      background-color: #393939;
    }

    ul {
      li {
        a {
          color: #ff0
        }

        a.lightMode {
          background-color: #fff;
        }
      }
    }

    .logo {
      .normal-theme {
        display: none;
      }

      .dark-theme {
        display: inline-block;
      }

      .mainLogo {
        filter: contrast(1) invert(1);
      }
    }

    .mainHeader {

      ul.navbar-nav li.nav-item a.nav-link,
      ul.navbar-nav li.nav-item a.nav-link svg,
      .loginBtn a.nav-link {
        color: #ff0;

        &:hover {
          color: #80ff00;
        }
      }

      .loginBtn a.nav-link {
        border-color: #ff0;

        &:hover {
          border-color: #80ff00;
          background-color: #80ff00;
          color: #000
        }
      }
    }
  }

  .bannerSection h1,
  .reportSection .pressRelease span,
  .reportSection .pressRelease .pdfIcon svg,
  .bannerSection .quote .quoteInner p.pmName {
    color: #fff;
  }

  .schemeSection .schemeInner h5,
  .reportingSection .reportingInner p,
  .reportSection .pressRelease .pdfDetails p,
  footer ul li a,
  footer p,
  .reportSection .viewAll a {
    color: #ff0
  }

  h2 {
    color: #80ff00
  }

  .bannerSection .quote .quoteInner p.quotation,
  .bannerSection .quote .quoteInner p.pmTag {
    color: #ff0
  }

  .bannerSection .quote .quoteInner .quotation::before,
  .bannerSection .quote .quoteInner .quotation::after {
    filter: brightness(10);
  }

  .reportingSection .reportingInner {
    box-shadow: none;
  }

  .logoSection {
    box-shadow: 0 -10px 20px #24242491;
    background-color: #333;
    .logoImage a {
      background: #fff;
      display: inline-grid;
      height: 74px;
      width: 100%;
      justify-content: center;
      align-items: center;
  }
  
  .logoImage {
      padding: 5px;
      height: 74px;
  }
  }

  .reportingSection {
    background-color: #222;
  }
  .pdfDetails p span{
    color: #fff!important;
  }
  footer{
    background-color: #111;
    svg{
      .cls-1 {
        fill: #ff0;
    }
    }
  }
  .InnerBanner ul.BreadCumb{
    li{
      color: #fff;
      &:before{
        color: #ff0;
      }
      a{
        color: #ff0;
      }
    }
  }
  .contactDiv{
    background-color: #333;
  }
  .AboutSection, .contactDiv, .screenReader{
    h5, h6, h4, h2{
      color: #ff0;
    }
    p, ul li{
      color: #fff;
      &:before{
        color: #80ff00;
      }
    }
  }
  .screenReader{
    .table-responsive{
      box-shadow: 0 3px 15px #0000004a;
      table{
        tr{
          border-color: #666;
          th, td{
            background-color: #444;
            color: #fff;
            a{
              color: #80ff00;
            }
          }
        }
      }
    }
  }
  .accordion .accordion-item{
    background-color: #555;
    color: #ff0;
    button{
      background-color: #555!important;
      color: #ff0!important;
      border-bottom-color: #999;
    }
    .accordion-body{
      background-color: #555;
      color:#fff;
      span{
        color: #fff!important;
      }
    }
  }
}