@media (min-width: 992px) {
    .gx-lg-5 {
        --bs-gutter-x: 2.5rem;
    }
}

.mainWrap.dashboard {
    .form-select:focus{
        box-shadow: none;
    }
    background-color: #F1F0F5;
    .rounded-bottom-0{
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
    }
    .rounded-top-0{
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    }
    .dashboardInner {
        padding: 10px 0;

        .heading {
            h2 {
                // color: rgba(29, 67, 130, 1);
                font-size: var(--sttl-font-size-30);
                letter-spacing: 0.76px;
                // font-weight: 600;
                display: flex;
                align-items: center;
                position: relative;
                padding-bottom: 16px;

                span {
                    color: rgba(50, 50, 50, 1);
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 400;
                    padding-left: 20px;
                    margin-left: 20px;
                    border-left: 1px solid #aaa;
                }

                &::before {
                    content: '';
                    background-color: rgba(245, 130, 22, 1);
                    height: 5px;
                    width: 56px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-radius: 10px;
                }
            }

            a.viewBtn {
                background: rgba(27, 47, 104, 1);
                color: #fff;
                text-decoration: none;
                border-radius: 9px;
                font-size: 14px;
                padding: 12px 20px;
                border: 1px solid rgba(27, 47, 104, 1);
                margin-bottom: 16px;
                display: inline-block;

                &:hover {
                    background: transparent;
                    color: rgba(27, 47, 104, 1);
                }
            }
        }
        .dashBox{
            background: #fff;
            padding: 15px;
            // border-radius: 7px;
            // box-shadow: 0 0 20px #d9d9d9;
            height: 100%;
            display: flex;
            // align-content: center;
            flex-wrap: wrap;
            position: relative;
            h6{
                font-size: 16px;
                width:100%;
                color: #707070;
                font-weight: 400;
            }
            h4{
                font-size: 20px;
            }
            .pqMainBox{
                width: 70%;
            }
            .pqChartBox{
                width: 30%;
                display: flex;
                justify-content: flex-end;
            }
            .pqBox{
                border-right: 1px solid #aaa;
                padding: 0 20px;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                    border-right: 0;
                }
                h4 {
                    margin-bottom: 0;
                    margin-top: 5px;
                }
                p {
                    margin-bottom: 0;
                    font-size: 15px;
                }
                .color-1{color:#005baa}
                .color-2{color:#ff647c}
                .color-3{color:#ffb990}
                .color-4{color:#f8c346}
            }
            .infoIcon {
                position: absolute;
                right: 10px;
                svg{
                    font-size: 28px;
                    color: #aaa;
                }
            }
        }
        .stripSection {
            background: #FFFFFF;
            border-radius: 18px;
            padding: 24px;
            margin-top: 30px;
            border: 1px solid #E1DEDE;
            box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04%);
            h5 {
                font-size: 21px;
                color: #1B2F68;
                font-weight: 600;
            }
            .col-md-4{
                border-right: 1px solid #CCCCCC;
                &:last-child{
                    border: 0;
                }
            }
        }

        .discomSection {
            margin-top: 15px;

            .discomLeft {
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 20px #d9d9d9;
                // background: #b2b9c5;
                // border: 1px solid rgba(225, 222, 222, 1);
                // border-radius: 18px;
                height: 100%;
                // background: #fff;
                .heading {
                    // padding: 25px 31px;
                    // background-color: rgba(245, 245, 249, 1);
                    // border-radius: 18px 18px 0 0;
                    h4{
                        font-size: 17px;
                        color: #1B2F68;
                        font-weight: 600;
                    }
                    h3 {
                        font-size: 22px;
                        font-weight: var(--sttl-font-bold);
                        color: rgba(27, 47, 104, 1);
                    }
                    .form-check{
                        label{
                            font-size: var(--sttl-font-size-14);
                        }
                    }
                    .form-check-input {
                        label{
                            font-size: var(--sttl-font-size-14);
                        }
                        &:checked[type=radio] {
                            background-image: url(../images//radio.svg);
                            background-size: 8px;
                        }

                        &:checked {
                            background-color: transparent;
                            border-color: #f58216;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }
                    select.w-25{
                        max-width: 100px;
                    }
                    select {
                        height: 42px;
                        font-size: var(--sttl-font-size-14);
                    }

                    .SearchBtn {
                        background: #1b2f68;
                        color: #fff;
                        text-decoration: none;
                        border-radius: 6px;
                        font-size: 14px;
                        padding: 8px 15px 10px 15px;
                        border: 1px solid #1b2f68;

                        &:hover {
                            background: transparent;
                            color: rgba(27, 47, 104, 1);
                        }
                    }                    
                }
                .dashBox{
                    h5{
                        font-size: 18px;
                    }
                }

                .mapSection {
                    // background: #fff;
                    // border-radius: 18px;
                    // padding: 36px 31px;
                    .IndiaSvg{
                        text-align: center;
                        margin-top: 50px;
                        svg{
                            width: 100%;
                            height: 654px;
                            // height: auto;
                            a{
                                path{
                                    stroke: #fff;
                                }
                            }
                        }
                        text{
                            pointer-events: none;
                        }
                        .activeState{
                            &:hover{
                                filter: brightness(1.2);
                                cursor: pointer;
                            }
                        }                                
                        .activeText{
                            fill:#000 !important;
                        }
                    }
                    ul{
                        list-style-type: none;
                        padding: 0;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        li{
                            span{
                                background-color: #005baa;
                                width: 10px;
                                height: 10px;
                                display: inline-block;
                            }
                            font-size: 14px;
                            &:last-child{
                                span{
                                    background-color: #a0a9fc;
                                }
                            }
                        }
                    }
                    .recState {
                        h3 {
                            font-size: 17px;
                            font-weight: 600;
                            color: #323232;
                        }

                        ul {
                            li {
                                border-bottom: 1px solid #cdcdcd;
                                padding: 2px 0;

                                a {
                                    text-decoration: none;
                                    color: #323232;
                                    font-size: 14px;
                                }

                                &:hover {
                                    a {
                                        color: #F84E33
                                    }
                                }
                            }
                        }
                    }
                }                         
            }
            .discomRight{
                .DiscomSummary, .ATcLoss{
                    background: #fff;
                    border-radius: 7px;
                    padding: 15px;
                    box-shadow: 0 0 20px #d9d9d9;
                    h4{
                        font-size: 17px;
                        color: #1B2F68;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    .d-flex{
                        margin-bottom: 20px;
                    }
                    select{
                        height: 42px;
                    }
                    .progressTitle{
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                            color: #0f5132;
                        }
                        svg.text-primary{
                            color: #084298;
                        }
                        span.alert{
                            padding: 3px 10px;
                            border: 0;
                            border-radius: 3px;
                            font-size: 12px;
                            font-weight: 600;
                            margin-left: 5px;
                        }
                    }
                }
                .ATcLoss{
                    margin-top: 15px;
                }
                .apexchartsLegendCustom{
                    &>div{
                        span{
                            width: 12px;
                            height: 12px;
                            display: inline-block;
                            border-radius: 2px;
                            margin-left: 17px;
                            margin-right: 5px;
                            position: relative;
                            top: 2px;
                        }
                        .bg-success{
                            background-color: #03c368!important;
                        }
                        span.sanctionedBG{background-color: #f9c267;}
                        span.awardBg{background-color: #cae3f7}
                        span.installBg{background-color: #62eeb6}
                        span.gbsBg{background-color: #61aff7}
                    }
                    font-size: var(--sttl-font-size-12);
                }
            }
        }
        
        .lsRM + .apexchartsLegendCustom{
            &>div{
                &:first-child{span{background-color: #e5e7fa!important;}}
                &:nth-child(2){span{background-color: #d0e3de!important}}
            }
        }
        .GrantsUtilized {
            margin-top: 32px;
            padding: 35px;
            border: 1px solid rgba(225, 222, 222, 1);
            border-radius: 18px;
            background-color: #fff;
            box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04);
            .col-lg-4.col-md-12{
                &:last-child{
                    .GrantsUtilizedInner{
                        .content{
                            border:0
                        }
                    }
                }
            }
            a {
                color: rgba(248, 105, 51, 1)
            }
            h3{
                font-size: var(--sttl-font-size-26);
                font-weight: var(--sttl-font-bold);
                color:var(--sttl-font-secondary)
            }
            .GrantsUtilizedInner {
                display: flex;
                .imgWrap {
                    margin-right: 20px;
                }

                .content {
                    border-right: 2px solid rgba(225, 222, 222, 1);

                    p {
                        font-weight: 500;
                        color: rgba(50, 50, 50, 1);
                        margin-bottom: 0;
                    }

                    h4 {
                        color: rgba(248, 105, 51, 1);
                        font-size: 39px;
                        font-weight: 500;
                        line-height: 59px;

                        span {
                            font-size: 26px;
                            line-height: 39px;
                        }
                    }
                }
            }
        }
    }

    .dashboardInner.detailedDashboard {
        select {
            height: 42px;
        }

        h2 {
            font-size: 27px;
            font-weight: 600;
            padding-bottom: 0;

            &::before {
                display: none;
            }
            a {
                background-color: #cbcbcb;
                color: #5a5a5a;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                line-height: 25px;
                text-align: center;
                font-size: 18px;
                margin-right: 20px;
                border: 1px solid #cbcbcb;
                transition: 0.3s ease-in-out all;
                &:hover{
                    background-color: transparent;
                    border: 1px solid #cbcbcb;
                }
            }
        }

        .backBtn {
            color: #F58216
        }

        .SearchBtn {
            background: #1b2f68;
            color: #fff;
            text-decoration: none;
            border-radius: 6px;
            font-size: 14px;
            padding: 8px 15px 10px 15px;
            border: 1px solid #1b2f68;

            &:hover {
                background: transparent;
                color: rgba(27, 47, 104, 1);
            }
        }
        .d-flex.topStripBox{
            border-radius: 7px;
            overflow: hidden;
            box-shadow: 0 0 20px #d9d9d9;
            .col-ts-1{
                width: 18%;
                // margin-right: 10px;
            }
            .col-ts-2{
                width: 18%;
                // margin-right: 10px;
            }
            .col-ts-3{
                width: 31%;
                // margin-right: 10px;
            }
            .col-ts-4{
                width: 35%;
            }
            .dashBox{
                position: relative;
                &::before{
                    content: '';
                    background-color: #aaa;
                    height: 80%;
                    width: 2px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

            }
            .col-ts-4
                .dashBox{
                    &::before{
                        display: none;
                    }
                }
            }
        
        .fundSection {
            margin-top: 40px;

            .funds {
                background: #fff;
                border-radius: 12px;
                box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04);

                .target,
                .installed,
                .pending {
                    padding: 28px 39px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(209, 209, 209, 1);

                    img {
                        background: rgba(142, 121, 201, 0.15);
                        width: 80px;
                        height: 80px;
                        padding: 17px 22px;
                        border-radius: 10px;
                        margin-right: 18px;
                    }

                    h4 {
                        font-size: 26px;
                        color: rgba(27, 47, 104, 1);
                        margin-bottom: 0;
                    }

                    p {
                        color: rgba(50, 50, 50, 1);
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                }

                .installed {
                    img {
                        background: rgba(102, 216, 147, 0.10);
                    }
                }

                .pending {
                    border-bottom: 0;

                    img {
                        background: rgba(58, 130, 238, 0.10);
                    }
                }
            }
        }

        .fundUtilization {
            background: #fff;
            border-radius: 12px;
            box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04);
            padding: 30px 39px;

            h4 {
                font-size: 22px;
                font-weight: 600;
            }
        }

        .consumer {
            margin-top: 30px;
            background: #fff;
            border-radius: 12px;
            box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.04);
            padding: 30px 39px;

            h4 {
                font-size: 22px;
                font-weight: 600;
            }
        }
    }
}
.mapPopover{
    border: 0;
    box-shadow: 3px 3px 21px #00000030;
    height: 65px;
    .popover-arrow::before{
        border: 0;
    }
    .mapHover {
        text-align: center;
        h6 {
            margin-bottom: 0;
            color: #F84E33;
            font-weight: 600;
            font-size: 20px;
        }
        
        p {
            margin-bottom: 0;
            font-size: 14px;
            color: #323232;
        }
        padding: 10px;
    }
}
.nice-dates {
    &:after {
        content: "";
        position: absolute;
        width: 12px;
        background-position: 50% 50%;
        background-size: 12px;
        top: 50%;
        right: 10px;
        height: 12px;
        transform: translateY(-50%);
    }

    input.form-control {
        background-position: 10px 50%;
        padding-left: 35px;
        background-size: 15px;
        position: relative;
        z-index: 1;
    }

    .nice-dates-popover {
        padding: 5px;
        box-shadow: 0 1px 8px #aaa;
        max-width: 270px;
    }
}

.nice-dates {
    a.nice-dates-navigation_previous:before {
        border-right: 2px solid #848484;
        border-top: 2px solid #848484;
        width: 10px;
        height: 10px;
    }

    .nice-dates-navigation_current {
        font-size: var(--sttl-font-size-14);
        font-weight: 500;
    }

    a.nice-dates-navigation_next:before {
        border-right: 2px solid #848484;
        border-top: 2px solid #848484;
        width: 10px;
        height: 10px;
    }

    .nice-dates-day {
        height: 38px;
        font-size: var(--sttl-font-size-14);

        span.nice-dates-day_month {
            display: none;
        }

        &:before {
            background: var(--sttl-font-primary);
        }

        &:after {
            border: 2px solid var(--sttl-font-primary);
        }
    }

    .nice-dates-day.-today {
        span {
            font-weight: 600;
            border-radius: 100%;
            color: var(--sttl-font-primary) !important;
        }
    }
    .date-range {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        div{
            max-width: 100%;
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
            margin-top: var(--bs-gutter-y);
            flex: 0 0 auto;
            width: 50%;
            position: relative;
            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                color: #F58216;
            }
        }
    }
    input {
        font-size: var(--sttl-font-size-14);
        width: 100%;
        height: 42px;
        border: 1px solid #ced4da;
        border-radius: 0.375em;
        padding: 0 15px;
        color: #212529;
    }
}
.smallLineChart{
    border: 1px solid #CCCCCC;
    padding: 20px 20px 5px;
    .apexcharts-title-text {
        font-weight: 600;
    }
    .apexcharts-legend{
        inset: auto 0px 0!important;
    }
}
.GrantBox {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 40px 20px;
    h3{
        margin-bottom: 15px;
    }
    p{
        margin-bottom: 5px;
        span{
            color: #aaa;
            padding-right: 20px;
        }
    }
    svg{
        font-size: 52px;
        color: #3f5f95;
    }
}
.stateDetail {
    h3 {
        color: #1d4382;
        font-weight: 600;
        margin-bottom: 0;
        & + button{
            svg{
                font-size: 20px;
            }
        }
    }
    svg {
        font-size: 36px;
        color: #03c368;
    }
}
.districtList ul{
    padding-left: 18px;
    li {
        margin-bottom: 10px;
    }  
}
.stateMap{
    // height: 900px;
    margin: 0 auto;
    text-align: center;
    width: 600px;
    img{
        // height: 100%;
    }
}
.dashDiscom{
    .AssetsChart{
        margin-left: 0;
    }
    .apexcharts-canvas{
        display: block;
        margin: 0 auto;
    }
}
.chartTitle {
    position: absolute;
    top: 48%;
    transform: translateY(-50%) translateX(-50%);
    width: 50%;
    text-align: center;
    left: 50%;
    h3 {
        font-size: 16px!important;
        font-weight: 700!important;
        line-height: 16px!important;
    }
}
.AssetsLegend {
    display: none;
    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        li {
            display: flex;
            font-size: 12px;
            margin-right: 10px;
            align-items: center;
            font-family: Helvetica, Arial, sans-serif;
            span {
                background: #03c368;
                height: 12px;
                width: 12px;
                display: inline-block;
                margin-right: 5px;
                border-radius: 2px;
            }
            &:last-child{
                span{
                    background: rgb(63, 95, 149);
                }
            }
        }
    }
}
.stateViewDiscomList{
    padding-left: 5px;
    margin-bottom: 0;
    list-style-type: none;
    li{
        position: relative;
        padding-left: 20px;
        line-height: 16px;
        margin-bottom: 10px;
        &:before{
            content: "\2022";
            color: var(--sttl-font-primary);
            font-weight: var(--sttl-font-bold);
            display: inline-block;
            position: absolute;
            left: 0;
            font-size: 20px;
            line-height: 0;
            margin-top: 9px;
        }
        a{
            font-size: var(--sttl-font-size-16);
            color: #4D4D4D;
            text-decoration: none;
            &:hover{
                color: var(--sttl-font-primary);
            }
            
        }
    }
}
.AssetsChart{
    &>div{
        margin-left:-20px
    }
}

@media (min-width: 1200px) {
    body .mainWrap.dashboard .container {
        max-width: 1316px;
        width: 100%;
    }
    body .SummeryChart .apexcharts-inner.apexcharts-graphical {
        transform: scale(1.5);
    }
}

.lossChartDiv.text-center{
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    margin-bottom: 23px;
    text-align: left!important;
    overflow: hidden;
    background: #f9f6fc;
    .d-flex{
        margin-bottom: 0!important;
        padding: 0px 15px 10px;
        .value {
            width: 65%;
        }
        .imgHead {
            width: 35%;
        }
    }
    p {
        color: #1a8aeb;
        font-weight: 600;
        font-size: 14px;
        & + p{
            color: #8590f0;
            & + p{
                color: #03c368;
            }
        }
    }
    img{
        width: 100%;
        height: 55px;
        margin-top: 5px;
    }
    h5{
        font-size: 15px;
        font-weight: 600;
        margin: 0 0 6px;
        background: #e5e7fa;
        padding: 8px 0px 8px 15px;
    }
    &.color {
        background: #ecfbff;
    }
} 
.lsRM{
    // border: 1px solid #e4e4e4;
    // border-radius: 10px;
    // padding: 20px;
    // margin-bottom: 15px;
    table{
        thead{
            th{
                border: 0;
                font-size: 14px;
                font-weight: 600;
            }
        }
        tbody{
            tr{
                td{
                    border-bottom: 5px solid #fff;
                    background-color: #e5e7fa;
                    text-align: center;
                    border-bottom: 5px solid #fff;                        
                    font-size: 14px;
                    &:first-child{
                        background-color: #f9f6fc;
                        text-align: left;
                        border-right: 5px solid #fff;                        
                    }
                    &:nth-child(3) {
                        border-right: 5px solid #fff;
                        background: #d0e3de;
                    }
                    &:last-child{
                        background: #d0e3de;
                    }
                }
            }
        }
    }
}
.NewTool button {
    position: absolute;
    top: 10px;
    right: 10px;
}
span.modalIcon svg {
    color: #f58216;
    font-size: 20px;
    margin-top: -4px;
}
.pqBoxIcn {
    background: #f0f1f4;
    padding: 10px;
    border-radius: 10px;
    width: 62px;
    & + div.w-50{
        min-width: 65%;
        text-align: start;
        padding-left: 10px;
        p{
            line-height: 17px;
            font-size: 13px!important;
        }
        h4{
            margin-top: 0!important;
        }
    }
}
.chart-container {
    padding-bottom: 20px;
    // border-radius: 10px;
    // box-shadow: 0 0 30px #e6e6e6;
    .apexcharts-data-labels {
        writing-mode: vertical-rl;
    }
}
.mainWrap.dashboard .dashboardInner .dashBox h6 + button {
    margin-top: -6px;
    padding: 0 0 0 7px;
}
.docCenter {
    border: 1px solid #dbdbdbaa;
    border-radius: 8px;
    padding: 0px 10px;
    text-align: left;
    position: relative;
    .cp{
        cursor: pointer;
    }
    svg{
        font-size: 60px;
    }
    h6{
        border-bottom:1px solid #ddd ;
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
        span{
            font-size: 10px;
        }
    }
    p{
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
    }
    img{
        height: 50px!important;
    }
    a.rename{
        width: 20px;
        height: 20px;
        color: #f58216;
        text-align: center;
        border-radius: 100%;
        line-height: 20px;
        svg {
            font-size: 16px !important;
            position: relative;
            bottom: 1px;
            left: 1px;
        }
    }
}

.stateViewNewChart {
    background: #fff;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0 0 20px #d9d9d9;
}
.stateChartModal .modal-dialog.modal-lg {
    max-width: 550px;
}


.customRow{
    --bs-gutter-x: .5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    >* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
    .customCol-1{
        flex: 0 0 auto;
        width: 8%;
    }
    .customCol-2{
        flex: 0 0 auto;
        width: 10%;
    }
    .customCol-3{
        flex: 0 0 auto;
        width: 11%;
    }
    .customCol-4{
        flex: 0 0 auto;
        width: 25%;
    }
}
.dashBox-1 {
    background: #fff;
    border-radius: 10px;
    padding: 15px 8px;
    text-align: center;
    min-height: 100%;
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent;
    position: relative;
    &:hover{
        border: 1px solid var(--sttl-font-primary);
        transition: 0.3s all ease-in-out;
        background: #ff810014;
        h6{
            color: #444;
            transition: 0.3s all ease-in-out;
        }
    }
    h4{
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 18px;
        sub {
            font-size: 12px;
            bottom: 0;
        }
    }
    h3{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    h5{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        svg{
            margin-left: 12px;
            font-size: 14px;
        }
    }
    h6{
        font-size: 12px;
        color: #aaa;    
        margin-bottom: 0;    
        font-weight: 400;    
        transition: 0.3s all ease-in-out;
    }
    button.btn{
        padding-right: 0;
        position: absolute;
        top: 5px;
        right: 10px;
        svg{
            color: #aaa;
        }
    }
    .form-select-sm{
        max-height: 30px;
    }
    img{
        height: 40px;
        margin-bottom: 12px;
    }
}
.progressDetailsChart {
    transform: scale(1.3);
    max-height: 225px;
    overflow: hidden;
    margin-top: -12px;
}
.smChart + .apexchartsLegendCustom {
    margin-top: -15px;
}
span.chartMainTitle {
    font-size: 16px;
    position: absolute;
    top: 30px;
    width: 100%;
    font-weight: 500;
    color: #007cff;
    left: 14px;
    a{
        color: #4494df;
    }
    &.anchorLink{
        top: unset;
        bottom: -5px;
        font-size: 12px;
    }
}
.progressDetailsChart + span.d-block.text-center {
    margin: -9px 0 5px;
    font-size: 12px;
    font-weight: 500;
}



.DiscomSummary ul {
    margin-bottom: 0!important;
    li {        
        background: #212f66;
        color: #fff;
        svg {
            font-size: 24px;
        }        
        input {
            position: absolute !important;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            width: 1px;
            border: 0;
            overflow: hidden;
        }
        label {
            background-color: transparent;
            padding: 10px 12px;
            color: #fff;
            font-size: 13px;
            text-align: center;
            transition: all 0.1s ease-in-out;
            &:hover{
                cursor: pointer;
            }
        }   
        input:checked + label {
            background-color: var(--sttl-font-primary);
            color: #fff;
        }
    }
}
.progressDetailsTable{
    max-height: 615px;
    overflow-y: scroll;
    table{
        border: 1px solid #262f64;
        thead{
            th{
                color: var(--sttl-font-secondary);
                font-size: var(--sttl-font-size-14);
                font-weight: 600;
                padding: 15px;
                border-bottom: 1px solid #dee2e6;
                min-width: 100px;
                position: sticky;
                top:0;
                background-color: #fff;
            }
        }
        tbody{
            td{
                padding: 15px 5px;
                a{
                    text-decoration: none;
                    color:#000;
                    transition: 0.3s all ease-in-out;
                    &:hover{
                        color: var(--sttl-font-primary);
                    }
                }
            }
        }
    }
}
.pdfBox, .excelBox{
    display: block;
    background: #f50000;
    color: #fff;
    padding: 10px 0;
    width: 50px;
    text-align: center;
    svg {
        font-size: 26px;
    }
}
.chartBreadCrumb {
    width: 51%;
    a{
        font-size: 12px;
        text-decoration: none;
        color: #000;
        sub{
            bottom: 0;
        }
        &:hover{
            color: var(--sttl-font-primary);
        }
    }
    ul {
        padding: 0;
        list-style-type: none;
        li {
            background: transparent;
            font-size: 12px;
            color: #6eb1f3;
            &:first-child{
                color: #bd7200;
            }
            &:last-child{
                color: #00af63;
            }
        }
    }
}
.chartBreadCrumb + .progressDetailsChart{
    width: 50%;   
}
.smartMeteringChart + span.chartMainTitle {
    top: 30px;
}

// Responsive

@media (min-width:1400px) and (max-width:1669px) {
    .mainWrap.dashboard .dashboardInner .discomSection{
        .col-xl-4.col-xxl-6.h-100{
            width: 40%;
        }
        .col-xl-8.col-xxl-6{
            width: 60%;
        }
    }
    .smartMeteringChart + span.chartMainTitle{
        left: 0px;
    }
    .chartBreadCrumb {
        width: 60%;
        span.d-block{
            font-size: 12px;
        }
        ul {
            li {
                font-size: 12px;
            }
        }
    }
    .discomRight h4{
        font-size: 16px!important;
    }
}
@media (max-width:1549px) {
    .dashBox-1{
        padding: 15px 6px;
        h4{font-size: 16px;sub{font-size: 10px;}}
        h3{font-size: 16px;}
        h6{font-size: 11px;}
    }
    .customRow .customCol-4 .dashBox-1{
        padding: 15px 10px;
    }
    .smartMeteringChart{
        padding: 0!important;
    }
    
}


.mapbg{
    display: none;
}
.IndiaMapLegend{
    margin-top: 15px;
    ul li {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-bottom: 10px;
        span {    
            background:#f4c33a;
            height: 22px;
            width: 40px;
            display: inline-block;
            margin-right: 10px;
        }
        &:first-child span{background: #a8d18d;}
        &:last-child span{background: #f36944;}
    }   
}       

.IndiaMapLegend span { font-size: 14px; }
.DiscomSummary h4 a { cursor: pointer; }
.stickyBack{
    background: var(--sttl-font-primary);
    border-radius: 5px;
    position: sticky;
    margin-left: auto;
    width: 140px;
    text-align: center!important;
    line-height: 40px;
    bottom: 5px;
    font-size: 18px;
    a{
        color: #fff!important;
    }
}
.row.discomViewBox .dashBox {
    border-radius: 5px;
    background-color: #f0f5fd!important;
    padding: 15px 10px!important;
    h5 {
        font-size: 14px!important;
    }
    .discIcon{
        background: #d1e3fd;
        border-radius: 100%;
        padding: 15px;
        max-width: 25%;
        width: 100%!important;
    }
    .w-75{
        padding-right: 10px;
    }
    p strong{
        font-size: 14px;
    }
    .form-select{
        font-size: 14px !important;
        width: 90px;
        padding-right: 15px;
        background-position: 4.4rem 0.7rem;
        background-size: 10px 10px;
        max-height: 31px;
        border-radius: 0;
        border: 0;
        background-color: #d3e4fc;
        margin-bottom: 5px;
    }
}
.fixColumn{
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
}
@media (max-width:1399px) {
    .customRow{
        .customCol-1{
            width: 50%;
            margin-bottom: 10px;
        }
        .customCol-2, .customCol-3{
            width: 50%;
            margin-bottom: 10px;
        }
        .customCol-4{
            width: 100%;
        }
    }
    .row.discomViewBox .dashBox {
        padding: 10px 10px !important;
    }
    .row.discomViewBox.stateViewBox .dashBox {
        padding: 15px 10px !important;
    }
    .dbStateView{
        .customRow{
            .customCol-1{
                &:nth-child(odd){width: 47%;}
                &:nth-child(even){width: 53%;}
            }
            .customCol-3{width: 47%;}
            .customCol-1.customCol-Last{
                margin-bottom: 0;
            }
            .customCol-1.customCol-Last + .customCol-4{
                max-width: 53%;
                .dashBox-1 h5{
                    font-size: 16px;
                    svg{
                        font-size: 12px;
                        margin-top: -2px;
                    }
                }
                .form-select-sm{
                    font-size: 10px;
                    max-height: 22px;
                    width: 65px;
                    padding-right: 10px;
                    background-position: right 0.3rem center;
                    background-size: 8px 8px;
                    margin-left: auto;
                }
            }
            .dashBox-1{padding: 10px 6px;}
        }
    }
    .discomLeft .mapSection .IndiaSvg svg {
        max-height: 465px;
        margin-top: 20px;
    }
}

@media (max-width:575px) {
    .discomRight .dashChart .DiscomSummary .d-flex.justify-content-between.align-items-center {
        display: block!important;
        text-align: center;
    }
    .discomRight .dashChart .DiscomSummary .d-flex.justify-content-between.align-items-center .progressTitle, .smartMeteringChart span.progressTitle{
        text-align: center;
        width: 100%;
        display: block;
        margin-top: 8px;
    }
    span.chartMainTitle{
        left: 0;
        text-align: center;
        margin-top: 10px;
        top:50px
    }
}