@mixin transition {
  transition: all 0.3s ease-out;
}

@mixin blankPara {
  background: #ECF1F9;
  color: #1B2F68;
  padding: 15px 5px 15px 20px;
  font-weight: 500;
}

@mixin commonButton {
  color: #fff;
  text-decoration: none;
  padding: 11px 27px;
  border-radius: 100px;
  margin-right: 20px;
  border: 0;
}

@mixin customFont($customFontVal) {
  font-size: $customFontVal;
}